import React from 'react';
import {  Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { PrivateRoute } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  Profile as ProfileView,
  Typography as TypographyView,
  Account as AccountView,
  SignUp as SignUpView,
  SignIn as SignInView,
} from './views';

import Feed from './views/Feed/Feed'
import Boards from './views/Boards/Boards'
import ForgotPassword from './views/Auth/ForgotPassword';
import Friends from './views/Friends/Friends'
import LogSession from './views/LogSession/LogSession'
import ResetPassword from './views/Auth/ResetPassword';
import Spots from './views/Spots/Spots'
import LandingPage from './views/LandingPage';

const Routes = props => {
  const { isAuthenticated } = props;
  return (
    <Switch>
      <Route
        exact
        path="/">
        {isAuthenticated ?
          <MainLayout><Feed /></MainLayout>
          :
          <MinimalLayout><LandingPage /></MinimalLayout>
        }
      </Route>
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path="/surf/:id?"
        render={props => (
          // Using a key ensures that the route gets reloaded if the user navigates
          // from /surf/something to /surf or /surf/something-else
          <MainLayout>
            <LogSession key={`/surf/${props.match.params.id || 'none'}`} />
          </MainLayout>
        )}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path="/friends">
          <MainLayout>
            <Friends />
          </MainLayout>
      </PrivateRoute>
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path="/boards">
          <MainLayout>
            <Boards />
          </MainLayout>
      </PrivateRoute>
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path="/spots">
          <MainLayout>
            <Spots />
          </MainLayout>
      </PrivateRoute>
      <Route
        exact
        path="/sign-up">
          <MinimalLayout>
            <SignUpView />
          </MinimalLayout>
      </Route>
      <Route
        exact
        path="/log-in">
          <MinimalLayout>
            <SignInView />
          </MinimalLayout>
      </Route>
      <Route
        isAuthenticated={isAuthenticated}
        exact
        path="/forgot-password">
          <MinimalLayout>
            <ForgotPassword />
          </MinimalLayout>
      </Route>
      <Route
        isAuthenticated={isAuthenticated}
        exact
        path="/reset-password">
        <MinimalLayout>
          <ResetPassword />
        </MinimalLayout>
      </Route>
      {/* TODO(ramin): remove these eventually */}
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path="/typography">
          <MainLayout>
            <TypographyView />
          </MainLayout>
      </PrivateRoute>
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path="/profile/:friendId?"
        render={props => (
          // Using a key ensures that the route gets reloaded if the user navigates
          // from /profile/something to /profile or /profile/something-else
          <MainLayout>
            <ProfileView key={`/profile/${props.match.params.friendId || 'none'}`} />
          </MainLayout>
        )}
      />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path="/account">
          <MainLayout>
            <AccountView />
          </MainLayout>
      </PrivateRoute>
      <Redirect to="/" />
    </Switch>
  );
};

Routes.propTypes = {
  isAuthenticated: PropTypes.bool
}

export default Routes;
