export default {
  root: {
    '&:last-child': {
      // Changed from 24.  Not entirely sure why it's 24 and not 16 like the
      // reset of the padding. Relevant issue where they decided it was fine as-is:
      // https://github.com/mui-org/material-ui/issues/9871
      paddingBottom: 16,
    },
  },
};
