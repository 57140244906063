import React, { useContext, useEffect, useState } from 'react';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import firebase from 'firebase/app';
import { Context } from '../../Store'
import { UserValidationSchema } from '../SignUp/SignUp'

const schema = {
  userName: UserValidationSchema.name,
};

const initialState = {
  isOpenEditName: false,
  isValid: true,
  values: {
    userName: '',
  },
  errors: {},
  touched: {},
  isSaving: false,
};

const AccountDetails = props => {
  const functions = firebase.functions();
  const currentUser = firebase.auth().currentUser;
  const [state, setState] = useState(initialState);
  const { enqueueSnackbar } = useSnackbar();
  const [, dispatch] = useContext(Context);

  useEffect(() => {
    const errors = validate(state.values, schema);

    setState(state => ({
      ...state,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [state.values]);

  // Reset state on *open* instead of close because there is a
  // delay / animation on close which would result in an ugly blip
  const openEditName = () => {
    setState({
      ...initialState,
      isOpenEditName: true,
      values: {
        ...initialState.values,
        userName: currentUser.displayName,
      }
    });
  };

  const closeEditName = () => {
    setState(state => ({ ...state, isOpenEditName: false }));
  };

  const handleSaveName = event => {
    event.preventDefault();

    setState(state => ({
      ...state,
      isSaving: true,
    }));

    const userName = state.values.userName.trim();
    return functions.httpsCallable('updateUserName')({ userName: userName })
      .then(() => {
        return currentUser.updateProfile({
          displayName: userName
        });
      })
      .then(() => {
        closeEditName();
        dispatch({ type: 'USER_NAME_UPDATED', payload: userName });
        enqueueSnackbar('Your name was updated');
      })
      .catch((error) => {
        const displayMessage = get(error, 'details.displayMessage')
        const errorMessage = displayMessage ? get(error, 'message') : 'There was a problem saving your name';
        enqueueSnackbar(errorMessage);
      })
      .finally(() => {
        setState(state => ({
          ...state,
          isSaving: false,
        }));
      });
  };

  const handleChange = event => {
    event.persist();

    // TODO(ramin): revisit this or at least share this code amoung the forms
    setState(state => ({
      ...state,
      values: {
        ...state.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...state.touched,
        [event.target.name]: true
      }
    }));
  };

  const {
    isSaving,
    isOpenEditName,
    isValid,
  } = state;

  // TODO(ramin): DRY this up!
  const hasError = field =>
    state.touched[field] && state.errors[field] ? true : false;

  return (
    <Grid item md={6} xs={12}>
      <Card>
        <CardHeader
          title="Name"
          action={
            <IconButton
              disabled={isSaving}
              onClick={openEditName}
              size="small"
            >
              <EditTwoToneIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Typography variant="h6">{currentUser.displayName}</Typography>
        </CardContent>
      </Card>
      <Dialog open={isOpenEditName} onClose={closeEditName} fullWidth>
        <DialogTitle id="form-dialog-title" disableTypography>
          <Typography variant='h4'>Update Name</Typography>
        </DialogTitle>
        <DialogContent>
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSaveName}
          >
            <TextField
              fullWidth
              label="Name"
              margin="dense"
              name="userName"
              onChange={handleChange}
              required
              value={state.values.userName}
              variant="outlined"
              error={hasError('userName')}
              helperText={
                hasError('userName') ? state.errors.userName[0] : null
              }
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            disabled={isSaving}
            onClick={closeEditName}
          >
            Cancel
            </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={isSaving || !isValid}
            onClick={handleSaveName}
          >
            Save
            </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AccountDetails;
