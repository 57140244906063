import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';
import isEmpty from 'lodash/isEmpty';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';

import { options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 200,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const SessionsChart = props => {
  const classes = useStyles();
  const { className, data, hasEverLogged, ...rest } = props;

  const chartData = {
    datasets: [
      {
        label: 'Sessions',
        backgroundColor: palette.primary.main,
        data,
      }
    ]
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Months"
      />
      <Divider />
      <CardContent>
        {isEmpty(data) &&
          <Typography variant="body1" color="textSecondary">
            {hasEverLogged && <span>No data</span>}
            {!hasEverLogged && <span>No session logged</span>}
          </Typography>
        }
        {!isEmpty(data) &&
          <div className={classes.chartContainer}>
            <Bar
              data={chartData}
              options={options}
            />
          </div>
        }
      </CardContent>
      <Divider />
    </Card>
  );
};

SessionsChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  hasEverLogged: PropTypes.bool,
};

export default SessionsChart;
