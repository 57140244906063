import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { SnackbarProvider, useSnackbar } from 'notistack';

// https://material-ui-pickers.dev/getting-started/installation
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Grid } from '@material-ui/core';

import theme from './theme';
import './assets/scss/index.css';
import validators from './common/validators';
import Routes from './Routes';
import Store from './Store';
import { Minimal as MinimalLayout } from './layouts';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import 'chartjs-adapter-luxon';

// TODO(ramin): this manual initialization shouldn't be necessary when using
// firebase hosting (which we are). Could not get it working though so I resorted
// to this.  See: "Initialize Firebase in your app (no need to include your Firebase config object when using reserved Hosting URLs)"
//https://firebase.google.com/docs/web/setup#from-hosting-urls
const app = firebase.initializeApp({
  apiKey: "AIzaSyANdsNkTnllm3ipnVu3OMUhFnE4O3SfSuA",
  authDomain: "thrashtown-17146.firebaseapp.com",
  databaseURL: "https://thrashtown-17146.firebaseio.com",
  projectId: "thrashtown-17146",
  storageBucket: "thrashtown-17146.appspot.com",
  messagingSenderId: "906740335219",
  appId: "1:906740335219:web:f9039ea8bd3866e9"
});


if (window.location.hostname === 'localhost') {
  app.functions().useEmulator('localhost', 5001);
  app.firestore().useEmulator('localhost', 8080);
  app.auth().useEmulator('http://localhost:9099/');
}

const auth = firebase.auth();
auth.useDeviceLanguage();

const browserHistory = createBrowserHistory();

validate.validators = {
  ...validate.validators,
  ...validators
};

const DismissAction = ({ id }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar(id)}
    >
      <CloseIcon />
    </IconButton>
  );
};

// Auth parts below inspired by this:
// https://medium.com/@sampsonjoliver/firebase-meet-react-hooks-db589c625106
const App = () => {
  const [authState, setAuthState] = useState({
    isCheckingAuth: true,
    user: null
  });

  const auth = firebase.auth()
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
        setAuthState({ isCheckingAuth: false, user: user });
      }
    );
    return unsubscribe;
  }, [auth]);

  const { isCheckingAuth, user } = authState;

  // TODO(ramin): refactor / componentize the spinner
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <Router history={browserHistory}>
          <SnackbarProvider
            action={key => <DismissAction id={key} />}
            maxSnack={1}>
            <Store>
              {isCheckingAuth ? (
                <MinimalLayout>
                  <Grid container justify="center">
                    <Box p={5}>
                      <CircularProgress />
                    </Box>
                  </Grid>
                </MinimalLayout>
                ) : <Routes isAuthenticated={!!user}/>
              }
            </Store>
          </SnackbarProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
