import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { SessionCard } from 'components'
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import last from 'lodash/last';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

const LIMIT = 25;

const Feed = () => {
  const { enqueueSnackbar } = useSnackbar();
  const db = firebase.firestore();
  const userId = firebase.auth().currentUser.uid;

  const classes = useStyles();
  const [state, setState] = useState({
    isLoadingResources: true,
    isLoadingSessionsInitial: true,
    sessions: [],
    isLoadingSessionsMore: false,
    showLoadMore: false,
  });

  let baseQuery = db.collectionGroup('surfs')
    .where('accessibleTo', 'array-contains', userId)
    .orderBy('sessionDate', 'desc')
    .limit(LIMIT);


  const fetchSessions = () => {
    setState(state => ({
      ...state,
      isLoadingSessionsInitial: true
    }));

    baseQuery
      .get()
      .then(querySnapshot => {
        setState(state => ({
          ...state,
          isLoadingSessionsInitial: false,
          sessions: querySnapshot.docs,
          showLoadMore: querySnapshot.docs.length === LIMIT,
        }));
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('There was an error loading your feed');
        setState(state => ({
          ...state,
          isLoadingSessionsInitial: false
        }));
      });
  };

  useEffect(fetchSessions, []);

  const loadMore = () => {
    setState(state => ({
      ...state,
      isLoadingSessionsMore: true
    }));


    baseQuery
      .startAfter(last(state.sessions))
      .get()
      .then(querySnapshot => {
        setState(state => ({
          ...state,
          sessions: state.sessions.concat(querySnapshot.docs),
          showLoadMore: querySnapshot.docs.length === LIMIT,
        }));
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('There was an error loading your feed');
      })
      .finally(() => {
        setState(state => ({
          ...state,
          isLoadingSessionsMore: false
        }));
      });
  };

  const isLoading = state.isLoadingSessionsInitial;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">
            Sessions
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.content}>
        {isLoading &&
          <Grid container justify="center">
            <Box p={5}>
              <CircularProgress />
            </Box>
          </Grid>
        }

        {!isLoading &&
          <Grid
            container
            spacing={3}
          >
          {state.sessions.map(session => {
            const id = session.id;
            const data = session.data();
            return (
              <Grid item xs={12} key={id}>
                <SessionCard sessionId={id} session={data} />
              </Grid>
              );
          })}
          {state.sessions.length === 0 &&
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Typography variant="body1" color="textSecondary">
                    There are no session yet to show in your feed.  Sessions you and your friends log will show up here.
                  </Typography>
              </CardContent>
              </Card>
            </Grid>
          }
          {state.showLoadMore &&
            <Grid item xs={12}>
              <Button
                variant="contained"
                disabled={state.isLoadingSessionsMore}
                onClick={loadMore}
              >Load More</Button>
            </Grid>
          }
          </Grid>
        }
      </div>
    </div>
  );
};

export default Feed;
