import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  TextField,
  Link,
  Typography
} from '@material-ui/core';

import Footer from '../../layouts/Footer'

import firebase from 'firebase/app';
import get from 'lodash/get';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 254
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    isSubmitting: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  let location = useLocation();

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = event => {
    event.preventDefault();

    event.preventDefault();
    closeSnackbar();
    setFormState(formState => ({
      ...formState,
      isSubmitting: true
    }));

    const {email, password} = formState.values;
    firebase.auth().signInWithEmailAndPassword(email, password).then((value) => {
      const path = get(location, 'state.from.pathname', '/');
      // TODO(ramin): this is somewhat of a hack.  To avoid this I would need
      // to manage slightly differently the logic in App.js `onAuthStateChanged`.
      // I would need to at a minimum set `isCheckingAuth` to true here (i.e.
      // with a cb or redux).  This hack forces the app to reload,
      // which ends up setting `isCheckingAuth` to true.
      // I couldn't find great examples of solutions for this for react <-> firebase apps.
      window.location = path;
    }).catch((reason) => {
      let message = 'Something went wrong, please try again';
      if (get(reason, 'code') === 'auth/wrong-password') {
        message = 'Invalid password, please try again.';
      } else if (get(reason, 'message')) {
        message = reason.message;
      }

      enqueueSnackbar(message);
      setFormState(formState => ({
          ...formState,
          isSubmitting: false
        }));
      return;
    });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
       <div className={classes.content}>
        <div className={classes.contentBody}>
          <form
            className={classes.form}
            onSubmit={handleSignIn}
          >
            <Typography
              className={classes.title}
              variant="h2"
            >
              Log In
            </Typography>
            <TextField
              className={classes.textField}
              error={hasError('email')}
              fullWidth
              helperText={
                hasError('email') ? formState.errors.email[0] : null
              }
              label="Email *"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.values.email || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={hasError('password')}
              fullWidth
              helperText={
                hasError('password') ? formState.errors.password[0] : null
              }
              label="Password *"
              name="password"
              onChange={handleChange}
              type="password"
              value={formState.values.password || ''}
              variant="outlined"
            />
            <Box mt={1}>
              <Link
                component={RouterLink}
                to="/forgot-password"
                variant="h6"
              >
                Forgot password?
              </Link>
            </Box>
            <Button
              className={classes.signInButton}
              color="primary"
              disabled={!formState.isValid || formState.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log in
            </Button>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Don't have an account?{' '}
              <Link
                component={RouterLink}
                to="/sign-up"
                variant="h6"
              >
                Sign up
              </Link>
            </Typography>
            <Footer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
