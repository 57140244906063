import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import { numericValidator } from '../../helpers';
import find from 'lodash/find';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import keyBy from 'lodash/keyBy';
import firebase from 'firebase/app';
import { DateTimePicker } from '@material-ui/pickers';
import SurfRating from './SurfRating'
import { boardLabel, sortBoardDocs } from 'views/Boards/Boards';
import { sortSpotDocs } from 'views/Spots/Spots';
import { timeZones } from '../../helpers';
import { ConfirmationDialog } from 'components';

const ratingLabels = {
  waveQuality: {
    left: 'Very Poor',
    right: 'Epic',
  },
  hollowness: {
    left: 'Crumbly',
    right: 'Barreling',
  },
  crowdedness: {
    left: 'Packed',
    right: 'Empty',
  },
  funFactor: {
    left: 'Not fun :(',
    right: 'Stoked!',
  },
};

const schema = {
  duration: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: numericValidator(0, 24, false),
  },
  description: {
    length: {
      maximum: 5000
    }
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
}));

const LogSession = () => {
  const db = firebase.firestore();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams();
  const currentUser = firebase.auth().currentUser;
  const userId = currentUser.uid;
  const isEdit = !!id;

  // IE 11 might not support this, in which case the user must pick something manually
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || '';
  const [state, setState] = useState({
    touched: {},
    errors: {},
    sessionDetails: {
      sessionDate: DateTime.fromObject({}, { zone: timeZone }),
      timeZone,
      duration: 2,
      board: null,
      spot: null,
      description: '',
      waveQuality: 3,
      hollowness: 3,
      crowdedness: 3,
      funFactor: 3,
      friends: [],
      otherFriends: 0,
      boardRef: null,
      spotRef: null,
      friendRefs: [],
    },
    isLoadingSpots: true,
    isLoadingBoards: true,
    isLoadingFriends: true,
    isLoadingExistingSession: true,
    isFullyLoaded: false,
    isOpenDeleteDialog: false,
    disableAll: false,
    showError: false,
    showTimeZoneSelector: !timeZone, // Show the selector in case it's IE11 to let the user pick something
    spots: [],
    spotsById: {},
    boards: [],
    boardsById: {},
    friends: [],
    friendsById: {},
    timeZones: timeZones,
    defaultBoardId: null,
    defaultSpotId: null,
  });

  const {
    isLoadingSpots,
    isLoadingBoards,
    isLoadingFriends,
    isFullyLoaded,
    isLoadingExistingSession,
    isOpenDeleteDialog,
    friendsById,
    boardsById,
    spotsById,
    showError,
    showTimeZoneSelector,
  } = state;

  // NB: This is a hack, the purpose of which is to set the "isFullyLoaded" state.
  // Ideally we'd do this in a less round-about way but the the onSnapshot methods
  // don't return promies so that presents a challenge.  It's worth using the
  // onSnapshot approach (instead of one-time load with .get()) because when we
  // hopefully add inline board and spot creation it would be nice to get that
  // those added to the dropdowns for free.
  if (!isFullyLoaded && !isLoadingSpots && !isLoadingBoards && !isLoadingFriends && !isLoadingExistingSession) {
    if (isEdit) {
      // On edit we fetch all archived boards/spots in case the current session used an
      // archived board/spot.  Ensure only that one archived board/spot is shown if that is
      // the case.  This is a slightly round-about way to going about this.
      setState(state => {
        const boards = state.boards.filter(({id}) => {
          return !state.boardsById[id].isArchived || get(state.sessionDetails.board, 'id', null) === id;
        });

        const spots = state.spots.filter(({id}) => {
          return !state.spotsById[id].isArchived || get(state.sessionDetails.spot, 'id', null) === id;
        });

        return {
          ...state,
          boards,
          spots,
          isFullyLoaded: true,
        };
      });
    } else {
      const defaultBoard = find(state.boards, {id: state.defaultBoardId});
      const defaultSpot = find(state.spots, {id: state.defaultSpotId});

      setState(state => ({
        ...state,
        isFullyLoaded: true,
        sessionDetails: {
          ...state.sessionDetails,
          board: defaultBoard || null,
          spot: defaultSpot || null,
        }
      }));
    }
  }

  const fetchExistingSession = () => {
    if (isEdit) {
      db.collection('users')
      .doc(userId)
      .collection('surfs')
      .doc(id)
      .get().then(doc => {
        if (!doc.exists) {
          enqueueSnackbar('The requested surf session does not exist')
          setState(state => ({
            ...state,
            disableAll: true,
            isLoadingExistingSession: false,
            showError: true,
          }));
        } else {
          const data = doc.data()

          const { timeZone } = data;
          setState(state => ({
            ...state,
            sessionDetails: {
              ...data,
              // Firestore stores these as "Timestamp" objects, which provide a
              // toDate method to convert to a JavaScript date.  Need to convert
              // before loading the form
              sessionDate: DateTime.fromJSDate(data.sessionDate.toDate(), { zone: timeZone }),

              // This gets set once before saving, so reset here and let the
              // save handler take care of it if need-be
              boardRef: null,
              spotRef: null,
              friendRefs: []
            },
            isLoadingExistingSession: false,
          }));
        }
      })
    } else {
      setState(state => ({
        ...state,
        isLoadingExistingSession: false,
      }));
    }
  };

  // TODO(ramin): all these fetch functions are duplicated in their respective pages
  const fetchSpots = () => {

    setState(state => ({
      ...state,
      isLoadingSpots: true
    }));

    let spotsCollection = db.collection('users')
      .doc(userId)
      .collection('spots');

    // Fetch archived spots in case the edited session included an archived board.
    // Once fully loaded, we'll filter out any archived spots that were not used
    // in the edited session to not diplay them.
    if (!isEdit) {
      spotsCollection = spotsCollection.where('isArchived', '!=', true);
    }

    const unsubscribe = spotsCollection
      .onSnapshot(querySnapshot => {
        let defaultSpotId = null;
        const spotsById = {};
        const spots = sortSpotDocs({docs: querySnapshot.docs, archivedLast: false}).map(doc => {
          const spot = doc.data();
          const spotId = doc.id;
          if (spot.isDefault) {
            defaultSpotId = spotId;
          }

          spotsById[spotId] = spot;

          return {
            name: spot.name,
            region: spot.region,
            id: spotId,
          };
        });

        setState(state => ({
          ...state,
          spots,
          spotsById,
          defaultSpotId,
          isLoadingSpots: false
        }));
      }, () => {
        enqueueSnackbar('There was an error loading your spots')
        setState(state => ({
          ...state,
          isLoadingSpots: false
        }));
      });

    return unsubscribe;
  };

  const fetchBoards = () => {
    setState(state => ({
      ...state,
      isLoadingBoards: true
    }));

    let boardsCollection = db.collection('users')
      .doc(userId)
      .collection('boards');

    // Fetch archived boards in case the edited session included an archived board.
    // Once fully loaded, we'll filter out any archived boards that were not used
    // in the edited session to not diplay them.
    if (!isEdit) {
      boardsCollection = boardsCollection.where('isArchived', '!=', true);
    }

    const unsubscribe = boardsCollection
      .onSnapshot(querySnapshot => {
        let defaultBoardId = null;
        const boardsById = {};
        const boards = sortBoardDocs({docs: querySnapshot.docs, archivedLast: false}).map(doc => {
          const board = doc.data();
          const boardId = doc.id;
          if (board.isDefault) {
            defaultBoardId = boardId;
          }

          boardsById[boardId] = board;

          return {
            name: board.name,
            lengthInches: board.lengthInches,
            lengthFeet: board.lengthFeet,
            type: board.type,
            id: boardId,
          };
        });

        setState(state => ({
          ...state,
          boards,
          boardsById,
          defaultBoardId,
          isLoadingBoards: false
        }));
      }, () => {
        enqueueSnackbar('There was an error loading your boards')
        setState(state => ({
          ...state,
          isLoadingBoards: false
        }));
      });

    return unsubscribe;
  };

  const fetchFriends = () => {
    setState(state => ({
      ...state,
      isLoadingFriends: true
    }));


    const unsubscribe = db.collection('users')
      .doc(userId)
      .collection('friends')
      .where('pending', '==', false)
      .onSnapshot(querySnapshot => {
        const friends = querySnapshot.docs.map(doc => {
          const friend = doc.data();
          return {
            name: friend.name,
            email: friend.email,
            id: doc.id,
          };
        });

        setState(state => ({
          ...state,
          friends,
          friendsById: keyBy(friends, 'id'),
          isLoadingFriends: false
        }));
      }, () => {
        enqueueSnackbar('There was an error loading your friends')
        setState(state => ({
          ...state,
          isLoadingFriends: false
        }));
      });

    return unsubscribe;
  };

  useEffect(fetchSpots, []);
  useEffect(fetchBoards, []);
  useEffect(fetchFriends, []);
  useEffect(fetchExistingSession, []);
  useEffect(() => {
    const errors = validate(state.sessionDetails, schema);

    setState(state => ({
      ...state,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [state.sessionDetails]);

  const handleTimeZoneToggle = () => {
    setState(state => ({
      ...state,
      showTimeZoneSelector: !state.showTimeZoneSelector,
    }));
  };

  const handleTimeZoneChange = (event, value) => {
    // This library provides the value directly, so create an "artificial"
    // wrapper object to keep a single consistent change handler
    handleChange({ target: { value, name: 'timeZone' } });

    // When changing the timezone, update the luxon DateTime object to reflect that change
    setState(state => {
      const { year, month, day, hour, minute } = state.sessionDetails.sessionDate;
      const sessionDate = DateTime.fromObject({year, month, day, hour, minute}, {zone: value});
      return ({
        ...state,
        sessionDetails: {
          ...state.sessionDetails,
          sessionDate,
        },
      });
    });
  };

  const handleChange = event => {
    if (isFunction(event.persist)) {
      // Because of how pass some values manually to handleChange we need to
      // check if there is an event or not. Not ideal, but the upside is we
      // still have a unified method for handling changes.
      event.persist();
    }

    setState(state => ({
      ...state,
      sessionDetails: {
        ...state.sessionDetails,
        [event.target.name]: event.target.value
      },
      touched: {
        ...state.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleRatingChange = sessionDetailsKey => {
    return (event, value) => {
      if (value === null) {
        // The rating library lets you unselect by clicking on the same rating
        // twice, which results in the rating having no selection. Rather than
        // deal with validating that, just make it so when clicking the current
        // rating does not change it
        return;
      }
      handleChange({target: {value, name: sessionDetailsKey}})
    }
  };

  const hasError = field =>
    state.touched[field] && state.errors[field] ? true : false;

  const handleSave = event => {
    event.preventDefault();
    closeSnackbar();

    setState(state => ({
      ...state,
      disableAll: true
    }));

    const surfCollection = db.collection('users')
      .doc(userId)
      .collection('surfs');

    const userDoc = db
      .collection('users')
      .doc(userId)

    const friendRefs = state.sessionDetails.friends.map(friend => {
      return db.collection('users').doc(friend.id);
    });

    let boardRef = null;
    const board = state.sessionDetails.board;
    if (board) {
      boardRef = userDoc
      .collection('boards')
      .doc(board.id)
    }

    let spotRef = null
    const spot = state.sessionDetails.spot;
    if (spot) {
      spotRef = userDoc
        .collection('spots')
        .doc(spot.id)
    }

    const accessibleTo = state.friends.map(friend => friend.id).concat(userId);

    // Need to convert the luxon DateTime object we use for the form back to a
    // normal JS date when storing in Firebase.
    const sessionDate = state.sessionDetails.sessionDate.toJSDate()
    const sessionToSave = {
      ...state.sessionDetails,
      sessionDate,
      updatedAt: new Date(),
      userName: currentUser.displayName,
      userId,
      accessibleTo,
      friendRefs,
      boardRef,
      spotRef,
    };

    let promise;
    if (isEdit) {
      promise = surfCollection
        .doc(id)
        .update(sessionToSave);
    } else {
      sessionToSave.createdAt = new Date();
      promise = surfCollection
        .add(sessionToSave);
    }

    return promise
      .then(() => {
        const action = isEdit ? 'updated' : 'saved';
        enqueueSnackbar(`Your session was ${action}`);
        history.push('/');
      })
      .catch((error) => {
        const action = isEdit ? 'updating' : 'saving';
        enqueueSnackbar(`There was an error ${action} your session`);
        setState(state => ({
          ...state,
          disableAll: false
        }));
      });
  };


  const handleDeleteDialogOpen = () => {
    setState(state => ({ ...state, isOpenDeleteDialog: true }));
  };

  const handleDeleteDialogClose = () => {
    setState(state => ({ ...state, isOpenDeleteDialog: false }));
  };

  const handleDelete = (event) => {
    event.preventDefault();

    setState(state => ({
      ...state,
      disableAll: true
    }));

    return db.collection('users')
      .doc(userId)
      .collection('surfs')
      .doc(id)
      .delete()
      .then(() => {
        enqueueSnackbar('Your session was successfully deleted');
        history.push('/');
      })
      .catch(() => {
        enqueueSnackbar('There was an error deleting your session');
        setState(state => ({
          ...state,
          disableAll: false
        }));
      })
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">
            {isEdit ? 'Edit' : 'Log'} Session
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <Card>
            <form
              autoComplete="off"
              noValidate
            >
              <CardContent>
                {!isFullyLoaded &&
                  <Grid container justify="center">
                    <Box p={5}>
                      <CircularProgress />
                    </Box>
                  </Grid>
                }

                {isFullyLoaded &&
                  <Grid
                    container
                    spacing={2}
                  >
                    { showError &&
                      <Grid
                          item
                          xs={12}
                        >
                          <Alert severity="error">The requested surf session does not exist</Alert>
                      </Grid>
                    }
                    <Grid
                      item
                      container
                      alignItems='center'
                      spacing={2}
                      md={6}
                      xs={12}
                    >
                      <Grid
                        item
                        md={6}
                        xs={12}
                      >
                        <DateTimePicker
                          fullWidth
                          label="Session Date and Time"
                          inputVariant="outlined"
                          margin="dense"
                          showTodayButton
                          required
                          value={state.sessionDetails.sessionDate}
                          disabled={state.disableAll}
                          onChange={value => {
                            // This library provides the value directly, so create an "artificial"
                            // wrapper object to keep a single consistent change handler
                            handleChange({target: {value, name: 'sessionDate'}})
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        md={5}
                        xs={11}
                      >
                        <TextField
                          fullWidth
                          label="Hours surfed"
                          name="duration"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          required
                          value={state.sessionDetails.duration}
                          error={hasError('duration')}
                          helperText={
                            hasError('duration') ? state.errors.duration[0] : null
                          }
                          onChange={handleChange}
                          inputProps={{ min: "0", max: "24", step: "0.25" }}
                          disabled={state.disableAll}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1}
                      >
                        <Tooltip title='Change time zone'>
                          <IconButton onClick={handleTimeZoneToggle} size="small">
                            <PublicIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} style={{ display: showTimeZoneSelector ? "block" : "none" }}>
                        <Collapse in={showTimeZoneSelector}>
                          <Autocomplete
                            size="small"
                            value={state.sessionDetails.timeZone}
                            disabled={state.disableAll}
                            options={state.timeZones}
                            required
                            disableClearable
                            onChange={handleTimeZoneChange}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="outlined"
                                margin="dense"
                                label="Time Zone"
                                helperText="You can change this if you are logging a session that was not in your current time zone"
                              />
                            )}
                          />
                        </Collapse>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <Autocomplete
                          size="small"
                          value={state.sessionDetails.board}
                          disabled={state.disableAll}
                          options={state.boards}
                          groupBy={(option) => option.type || 'No type' }
                          getOptionSelected={(option, value) => option.id === value.id}
                          getOptionLabel={(board) => {
                            const labels = [boardLabel(board)];
                            if (boardsById[board.id].isArchived) {
                              labels.push('(archived)')
                            }

                            return labels.join(' ');
                          }}
                          onChange={(event, value) => {
                            // This library provides the value directly, so create an "artificial"
                            // wrapper object to keep a single consistent change handler
                            handleChange({target: {value, name: 'board'}})
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              margin="dense"
                              label="Board"
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <Autocomplete
                          size="small"
                          value={state.sessionDetails.spot}
                          options={state.spots}
                          groupBy={(option) => option.region || 'No region' }
                          disabled={state.disableAll}
                          getOptionSelected={(option, value) => option.id === value.id}
                          getOptionLabel={({id, name}) => {
                            let label = name;
                            if (spotsById[id].isArchived) {
                              label += ' (archived)';
                            }

                            return label;
                          }}
                          onChange={(event, value) => {
                            // This library provides the value directly, so create an "artificial"
                            // wrapper object to keep a single consistent change handler
                            handleChange({target: {value, name: 'spot'}})
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              variant="outlined"
                              margin="dense"
                              label="Spot"
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <Autocomplete
                          multiple
                          size="small"
                          value={state.sessionDetails.friends}
                          options={state.friends}
                          disabled={state.disableAll}
                          getOptionSelected={(option, value) => option.id === value.id}
                          getOptionLabel={friend => `${friend.name} (${friendsById[friend.id].email})`}
                          filterSelectedOptions
                          onChange={(event, value) => {
                            // This library provides the value directly, so create an "artificial"
                            // wrapper object to keep a single consistent change handler
                            // Also, remove the email from what we save to firestore.
                            const friends = value.map(({name, id}) => ({name, id}));
                            handleChange({target: {value: friends, name: 'friends'}})
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              margin="dense"
                              label="Friends"
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                      <TextField
                          fullWidth
                          label="Other Friends"
                          name="otherFriends"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          value={state.sessionDetails.otherFriends}
                          disabled={state.disableAll}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            inputProps: {
                              min: '0',
                              max: '50',
                              step: '1'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      alignItems="center"
                      justify="center"
                      spacing={2}
                      md={6}
                      xs={12}
                    >
                      <SurfRating
                        title="Wave Quality"
                        inputName="waveQuality"
                        ratingValue={state.sessionDetails.waveQuality}
                        onChangeValue={handleRatingChange('waveQuality')}
                        ratingLabels={ratingLabels.waveQuality}
                        disabled={state.disableAll}
                      />
                      <SurfRating
                        title="Hollowness"
                        inputName="hollowness"
                        ratingValue={state.sessionDetails.hollowness}
                        onChangeValue={handleRatingChange('hollowness')}
                        ratingLabels={ratingLabels.hollowness}
                        disabled={state.disableAll}
                      />
                      <SurfRating
                        title="Crowdedness"
                        inputName="crowdedness"
                        ratingValue={state.sessionDetails.crowdedness}
                        onChangeValue={handleRatingChange('crowdedness')}
                        ratingLabels={ratingLabels.crowdedness}
                        disabled={state.disableAll}
                      />
                      <SurfRating
                        title="Fun Factor"
                        inputName="funFactor"
                        ratingValue={state.sessionDetails.funFactor}
                        onChangeValue={handleRatingChange('funFactor')}
                        ratingLabels={ratingLabels.funFactor}
                        disabled={state.disableAll}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Session Description"
                        name="description"
                        multiline
                        placeholder="An optional description of your session"
                        variant="outlined"
                        value={state.sessionDetails.description}
                        disabled={state.disableAll}
                        onChange={handleChange}
                        error={hasError('description')}
                        helperText={
                          hasError('description') ? state.errors.description[0] : null
                        }
                      />
                    </Grid>
                  </Grid>
                }
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={state.disableAll}
                  onClick={handleSave}
                >
                  {isEdit ? 'Udpate' : 'Save' }
                </Button>
                {isEdit &&
                  <Button
                    variant="outlined"
                    color="secondary"
                    disabled={state.disableAll}
                    onClick={handleDeleteDialogOpen}
                  >
                    Delete
                  </Button>
                }
                <ConfirmationDialog
                  isOpen={isOpenDeleteDialog}
                  title="Are you sure you want to delete this session?"
                  description="This action cannot be undone."
                  onCancel={handleDeleteDialogClose}
                  onConfirm={handleDelete}
                />
              </CardActions>
            </form>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default LogSession;
