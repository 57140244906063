import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Typography,
} from '@material-ui/core';

import ProfilePicture from './ProfilePicture';
import UserName from './UserName';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
}));

const Account = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1">
            Account
          </Typography>
        </Grid>
        <Grid item container spacing={3}>
          <ProfilePicture />
          <UserName />
        </Grid>
      </Grid>
    </div>
  );
};

export default Account;
