const numericValidator = (min, max, isInteger) => {
  return (val) => {
    if (val === '') {
      // Workaround for this issue (empty string binding is resulting in invalid)
      // https://github.com/ansman/validate.js/issues/297
      return false;
    } else {
      return {
        onlyInteger: isInteger,
        greaterThanOrEqualTo: min,
        lessThanOrEqualTo: max
      };
    }
  };
};

export default numericValidator;
