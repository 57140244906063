import React, {Fragment, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { useSnackbar } from 'notistack';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from '@material-ui/core';
import UserAvatar from 'components/UserAvatar';
import UserNameProfileLink from 'components/UserNameProfileLink';

import firebase from 'firebase/app';

const useStyles = makeStyles(theme => ({
  root: {},
  listItemRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  content: {
    padding: 0,
    '&:last-child': {
      padding: 0
    }
  },
  cancel: {
    marginLeft: theme.spacing(2)
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
}));

const byAcceptedAt = friend => friend.data().acceptedAt;
const byPending = friend => !friend.data().pending;

const FriendsTable = props => {
  const functions = firebase.functions();
  const { className, friends, isLoading, stats } = props;
  const friendsSorted = sortBy(friends, [byPending, byAcceptedAt]);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState({
    isDisabledActions: false
  });

  const respond = (event, inviterUid, name, email, action) => {
    event.preventDefault();
    const successMessage = action === 'ACCEPT' ?
      'You are now friends with' :
      'Invitation deleted from from'

    setState(state => ({
      isDisabledActions: true
    }));

    functions.httpsCallable('acceptOrRejectFriend')({inviterUid, action})
      .then(() => enqueueSnackbar(`${successMessage} ${name} (${email})`))
      .catch((err) => {
        console.error(err);
        const errorMessage = action === 'ACCEPT' ?
          'There was a problem confirming the pending invitation from' :
          'There was a problem deleting the pending invitation from ';
        enqueueSnackbar(`${errorMessage} ${name} (${email})`);
      })
      .finally(() => setState(state => ({isDisabledActions: false})));
  };

  const subscribe = (event, doSubscribe, subscribeeId, friendName) => {
    event.preventDefault();

    let successMessage;
    if (doSubscribe) {
      successMessage = `You are now subscribed to ${friendName}. You will
      receive an email notification when your friend logs a session.`
    } else {
      successMessage = `You will no longer receive email notifications when
      ${friendName} logs sessions.`
    }

    functions.httpsCallable('subscribeToFriend')({ subscribeeId, doSubscribe })
      .then(() => enqueueSnackbar(successMessage))
      .catch((err) => {
        console.error(err);
        const errorMessage = doSubscribe ?
          `There was a problem subscribing to ${friendName}.  Try again.` :
          `There was a problem unsubscribing from ${friendName}. Try again.`;
        enqueueSnackbar(errorMessage);
      });
  }

  const spinner = (
    <TableRow>
      <TableCell colSpan={5} align="center">
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Friends Since</TableCell>
                <TableCell>Sessions</TableCell>
                <TableCell align="center">
                  Subscribed To
                  <Tooltip title="You can receive email notifications when your friends log a session">
                    <IconButton size="small">
                      <InfoOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? spinner : friendsSorted.map(friend => {
                const data = friend.data();
                const isPending = data.pending;
                let subscribedToToolip;
                if (data.amSubscribedTo) {
                  subscribedToToolip = 'Click to stop receiving email notification on logged sessions'
                } else {
                  subscribedToToolip = 'Click to receive email notification on logged sessions'
                }

                let acceptedAt;
                if (data.acceptedAt) {
                  acceptedAt = DateTime
                    .fromJSDate(data.acceptedAt.toDate())
                    .toLocaleString({month: 'short', year: 'numeric'});
                }

                return (
                  <TableRow hover key={friend.id}>
                    <TableCell>

                      <ListItem
                        disableGutters
                        classes={{
                          root: classes.listItemRoot
                        }}
                      >
                        <ListItemAvatar>
                          <UserAvatar
                            userName={data.name}
                            userId={data.userId}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={<UserNameProfileLink isPending={isPending} userId={friend.id} name={data.name} />}
                          secondary={
                          <span>{data.email}
                            {isPending && <Typography variant="body2" component="span" color="secondary"><br/>Pending Invitation</Typography>}
                          </span>}
                        />
                      </ListItem>
                    </TableCell>
                    {isPending ?
                      <Fragment>
                        <TableCell colSpan={3}>
                          <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            disabled={state.isDisabledActions}
                            onClick={(e) => respond(e, friend.id, data.name, data.email, 'ACCEPT')}
                          >
                            Accept
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            className={classes.cancel}
                            disabled={state.isDisabledActions}
                            onClick={(e) => respond(e, friend.id, data.name, data.email, 'REJECT')}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </Fragment>
                      :
                      <Fragment>
                        <TableCell>{acceptedAt}</TableCell>
                        <TableCell>{get(stats, ['friends', friend.id], 0)}</TableCell>
                        <TableCell align="center">
                          <Tooltip title={subscribedToToolip}>
                            <Switch
                              checked={!!data.amSubscribedTo}
                              onChange={(e) => subscribe(e, !data.amSubscribedTo, friend.id, data.name)}
                            />
                          </Tooltip>
                        </TableCell>
                      </Fragment>
                    }
                  </TableRow>);
              })}
              {!isLoading && !friendsSorted.length &&
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    Add friends to tag them in your sessions
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

FriendsTable.propTypes = {
  className: PropTypes.string,
  friends: PropTypes.array.isRequired,
  stats: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default FriendsTable;
