import React from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { Link as RouterLink } from 'react-router-dom';
import {
  Link,
} from '@material-ui/core';

// Not passing userId in denotes it is for the current user
const UserNameProfileLink = ({ isPending = false, userId = null, name, ...rest }) => {
  if (isPending) {
    return <span>{name}</span>;
  } else {

    let isCurrentUser;
    if (userId) {
      isCurrentUser = userId === firebase.auth().currentUser.uid;
    } else {
      isCurrentUser = true
    }

    const to = isCurrentUser ? '/profile' : `/profile/${userId}`;
    return (<Link
      component={RouterLink}
      to={to}
      {...rest}
    >
      {name}
    </Link>);
  }
};

UserNameProfileLink.propTypes = {
  isPending: PropTypes.bool,
  userId: PropTypes.string,
  name: PropTypes.string,
};

export default UserNameProfileLink;
