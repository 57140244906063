import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiCardActions from './MuiCardActions';
import MuiCardContent from './MuiCardContent';
import MuiCardHeader from './MuiCardHeader';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiToggleButton from './MuiToggleButton';
import MuiTypography from './MuiTypography';

export default {
  MuiButton,
  MuiCardActions,
  MuiCardContent,
  MuiCardHeader,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTableHead,
  MuiToggleButton,
  MuiTypography,
};
