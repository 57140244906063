import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(1)
  },
  count: {
    textAlign: 'right',
    color: theme.palette.primary.main,
  }
}));

const SessionCountCard = props => {
  const { count } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      item
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={10}>
        <Typography
          variant="h4"
        >
          Sessions
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          color="inherit"
          variant="h3"
          className={classes.count}
        >
          {count}
        </Typography>
      </Grid>
    </Grid>
  );
};

SessionCountCard.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
};

export default SessionCountCard;
