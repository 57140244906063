import React from 'react';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';


const StyledRating = withStyles(({ palette }) => ({
  iconFilled: {
    color: palette.secondary.main,
  },
}))(Rating);

const SurfRating = (props) => {
  const { ratingValue, ratingLabels, onChangeValue, title, inputName, disabled } = props;

  return (
    <>
    <Grid
      item
      xs={12}
      style={{paddingBottom: 0}}
    >
      <Box textAlign="center"><Typography component="legend">{title}</Typography></Box>
    </Grid>
      <Grid
        container
        item
        xs={12}
        justify="center"
        spacing={1}
        >
      <Grid
        item
      >
        <Box textAlign="right" style={{ width: 55 }}><Typography variant="caption">{ratingLabels.left}</Typography></Box>
      </Grid>
      <Grid
        item
      >
        <Box textAlign="center">
          <StyledRating
            name={inputName}
            value={ratingValue}
            precision={1}
            onChange={onChangeValue}
            disabled={disabled}
            size="medium"
          />
        </Box>
      </Grid>
      <Grid
        item
      >
        <Box textAlign="left" style={{ width: 55 }}><Typography variant="caption">{ratingLabels.right}</Typography></Box>
      </Grid>
    </Grid>
    </>
  );
};

SurfRating.propTypes = {
  ratingValue: PropTypes.number,
  onChangeValue: PropTypes.func,
  title: PropTypes.string,
  ratingLabels: PropTypes.object,
};

export default SurfRating;
