import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import partition from 'lodash/partition';
import startCase from 'lodash/startCase';
import { makeStyles } from '@material-ui/styles';
import ArchiveTwoToneIcon from '@material-ui/icons/ArchiveTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import KeyboardArrowDownTwoToneIcon from '@material-ui/icons/KeyboardArrowDownTwoTone';
import KeyboardArrowRightTwoToneIcon from '@material-ui/icons/KeyboardArrowRightTwoTone';
import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone';
import {
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import firebase from 'firebase/app';
import {
  ConfirmationDialog
} from './index';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    '&:last-child': {
      padding: 0
    }
  },
  actions: {
    minWidth: 95
  },
  archived: {
    color: theme.palette.text.secondary,
  },
  showArchived: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'inherit',
  },
}));

const EntityRow = props => {
  const classes = useStyles();
  const {
    entity,
    isLoadingStats,
    onArchive,
    onDelete,
    onEdit,
    rowComponent: RowComponent,
    stats,
    statsKey,
  } = props;

  const data = entity.data();
  const entityId = entity.id
  const sessionCount = get(stats, [statsKey, entityId], 0)
  const hasSession = sessionCount > 0;

  return (
    <TableRow hover>
      <RowComponent entity={entity} sessionCount={sessionCount} />
      {isLoadingStats &&
        <TableCell align="center" className={classes.actions}>
          <CircularProgress size={20}/>
        </TableCell>
      }
      {!isLoadingStats &&
        <TableCell align="center" className={classes.actions}>
          {!data.isArchived &&
            <Tooltip title="Edit">
              <IconButton
                size="small"
                onClick={() => onEdit(entity)}
              >
                <EditTwoToneIcon />
              </IconButton>
            </Tooltip>
          }
          {!hasSession &&
            <Tooltip title="Delete">
              <IconButton
                size="small"
                onClick={() => onDelete(entityId)}
              >
                <DeleteTwoToneIcon />
              </IconButton>
            </Tooltip>
          }
          {hasSession &&
            <Tooltip title={data.isArchived ? 'Restore' : 'Archive'}>
              <IconButton
                size="small"
                onClick={() => onArchive(entityId, !data.isArchived)}
              >
                {data.isArchived ? <ReplayTwoToneIcon /> : <ArchiveTwoToneIcon />}
              </IconButton>
            </Tooltip>
          }
        </TableCell>
      }
    </TableRow>);
};

const EntityTable = props => {
  const db = firebase.firestore();
  const userId = firebase.auth().currentUser.uid;
  const { enqueueSnackbar } = useSnackbar();
  const {
    className,
    dbCollection,
    entities,
    labelPlural,
    labelSingular,
    headerComponent: HeaderComponent,
    isLoading,
    labelNullState,
    onEdit,
    rowComponent,
    statsKey,
    surfLogProperty,
  } = props;

  const [archived, active] = partition(entities, b => b.data().isArchived)

  const classes = useStyles();

  const [state, setState] = useState({
    showArchived: false,
    isPerformingAction: false,
    isLoadingStats: true,
    stats: {},
    deleteEntity: {
      isOpen: false,
      entityId: null
    },
  });

  const fetchStats = () => {
    db.collection('stats')
      .doc(userId)
      .get()
      .then(doc => {
        let stats = {};
        if (doc.exists) {
          stats = doc.data();
        }

        setState(state => ({
          ...state,
          stats,
          isLoadingStats: false,
        }));
      })
      .catch(err => {
        console.log(err);
        enqueueSnackbar('There was an error loading your data');
      });
  };

  useEffect(fetchStats, []);

  const toggleArchivedSection = () => {
    setState(state => ({
      ...state,
      showArchived: !state.showArchived,
    }));
  };

  const handleArchive = (entityId, doArchive) => {
    setState(state => ({
      ...state,
      isPerformingAction: true,
    }));

    const updatedEntity = {
      isArchived: doArchive,
    };

    if (doArchive) {
      // Set it to false in case it is the default already
      // (not important to check if it already is)
      updatedEntity.isDefault = false;
    }

    dbCollection.doc(entityId)
      .update(updatedEntity)
      .catch(err => {
        console.error(err);
        const actionText = doArchive ? 'archiving' : 'unarchiving'
        enqueueSnackbar(`There was an error ${actionText} the ${labelSingular}`, { variant: 'error' });
      })
      .finally(() => {
        setState(state => ({
          ...state,
          isPerformingAction: false,
          // Always open up archived when an entity gets archived
          showArchived: true,
        }));
      });
  };

  const openDeleteDialog = entityId => {
    setState(state => ({...state, deleteEntity: { isOpen: true, entityId }}));
  };

  const closeDeleteDialog = () => {
    setState(state => ({
      ...state,
      isPerformingAction: false,
      deleteEntity: {
        isOpen: false,
        entityId: null,
       }
     }));
  };

  const confirmDelete = () => {
    setState(state => ({
      ...state,
      isPerformingAction: true,
    }));

    const entityRef = dbCollection.doc(state.deleteEntity.entityId);
    return db.collection('users')
      .doc(userId)
      .collection('surfs')
      .where(surfLogProperty, '==', entityRef)
      .limit(1)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          throw new Error(`${labelSingular} has already been used to log a session`);
        }

        return entityRef.delete();
      })
      .then(() => enqueueSnackbar(`${startCase(labelSingular)} successfully deleted`))
      .catch(err => {
        console.error(err);
        enqueueSnackbar(`There was an error deleting the ${labelSingular}`, { variant: 'error' });
      })
      .finally(closeDeleteDialog);
  };

  const spinner = (
    <TableCell colSpan={5} align="center">
      <CircularProgress />
    </TableCell>
  );

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <HeaderComponent />
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (<TableRow>{spinner}</TableRow>) }
              {!isLoading && active.map(entity => {
                return (
                  <EntityRow
                    key={entity.id}
                    entity={entity}
                    isLoadingStats={state.isLoadingStats}
                    rowComponent={rowComponent}
                    onArchive={handleArchive}
                    onDelete={openDeleteDialog}
                    onEdit={onEdit}
                    stats={state.stats}
                    statsKey={statsKey}
                  />
                );
              })}
              {!isLoading && !entities.length &&
                <TableRow className={classes.tableRow}>
                  <TableCell colSpan={5} align="center">
                    {labelNullState}
                  </TableCell>
                </TableRow>
              }
              {!isLoading && !!archived.length &&
                <Fragment>
                  <TableRow hover key='archive_divider' onClick={toggleArchivedSection}>
                    <TableCell colSpan={5} className={classes.archived}>
                      <span>Archived {labelPlural}</span>
                      <IconButton size="small" className={classes.showArchived}>
                        {state.showArchived ? <KeyboardArrowDownTwoToneIcon /> : <KeyboardArrowRightTwoToneIcon />}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  {state.showArchived && archived.map(entity => {
                    return (
                      <EntityRow
                        key={entity.id}
                        entity={entity}
                        isLoadingStats={state.isLoadingStats}
                        rowComponent={rowComponent}
                        onArchive={handleArchive}
                        onDelete={openDeleteDialog}
                        onEdit={onEdit}
                        stats={state.stats}
                        statsKey={statsKey}
                      />
                    );
                  })}
                </Fragment>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <ConfirmationDialog
        isOpen={state.deleteEntity.isOpen}
        isDisabled={state.isPerformingAction}
        title={`Are you sure you want to delete this ${labelSingular}?`}
        description="This action cannot be undone."
        onCancel={closeDeleteDialog}
        onConfirm={confirmDelete}
      />
    </Card>
  );
};

EntityRow.propTypes = {
  entity: PropTypes.object.isRequired,
  isLoadingStats: PropTypes.bool.isRequired,
  rowComponent: PropTypes.elementType.isRequired,
  onArchive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  stats: PropTypes.object.isRequired,
  statsKey: PropTypes.string.isRequired,
};

EntityTable.propTypes = {
  className: PropTypes.string,
  entities: PropTypes.array.isRequired,
  dbCollection: PropTypes.object.isRequired,
  headerComponent: PropTypes.elementType,
  isLoading: PropTypes.bool.isRequired,
  labelNullState: PropTypes.string.isRequired,
  labelPlural: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  rowComponent: PropTypes.elementType,
  statsKey: PropTypes.string.isRequired,
  surfLogProperty: PropTypes.string.isRequired,
};

export default EntityTable;
