import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import firebase from 'firebase/app';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 254
    }
  },
  region: {
    length: {
      maximum: 254
    }
  },
};

const initialState = {
  isValid: false,
  isSubmitting: false,
  touched: {},
  errors: {},
  values: {
    name: '',
    region: '',
    isDefault: false,
  },
};

// TODO(ramin): super similar to EditBoard - DRY it up
const EditSpot = props => {
  const db = firebase.firestore();
  const userId = firebase.auth().currentUser.uid;

  const { spot, handleClick, handleClose, isOpen } = props;
  const isEdit = !!spot;

  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    if (isOpen) {
      // Only reset props on open. This ensures that values in the form
      // aren't cleared as the dialog close is still animating (which would be ugly).
      if (spot) {
        setFormState(formState => ({
          ...initialState,
          values: ({...formState.values, ...spot.data()})
        }));
      } else {
        setFormState(formState => (initialState))
      }
    }
  }, [isOpen, spot])

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSave = event => {
    event.preventDefault();
    closeSnackbar();

    setFormState(formState => ({
      ...formState,
      isSubmitting: true
    }));

    const { name, region, isDefault } = formState.values;
    const spotToSave = {
      name: name.trim(),
      region: region.trim(),
      isDefault: isDefault,
      isArchived: false,
    };

    const spotCollection = db.collection('users')
        .doc(userId)
        .collection('spots');

    let promise;
    if (isEdit) {
      promise = spotCollection
        .doc(spot.id)
        .update(spotToSave)
        .then(() => spot.id);
    } else {
      promise = spotCollection
      .add(spotToSave)
      .then((spot) => spot.id);
    }

    return promise.then(updatedSpotId => {
      // If this is the default spot, reset the other default spot if there
      // is one.
      // Note: in theory there should ever be at most only one default spot,
      // so this loop might be somewhat unecessary, but safe to do in case somehow
      // there are multiple default spots.
      if (formState.values.isDefault) {
        return spotCollection.where('isDefault', '==', true)
          .get()
          .then(querySnapshot => {
            const batch = db.batch()
            let counter = 0;
            querySnapshot.forEach(doc => {
              const spotId = doc.id;
              if (spotId !== updatedSpotId) {
                batch.update(spotCollection.doc(spotId), {isDefault: false});
                counter++;
              }
            });
            if (counter) {
              return batch.commit();
            }
          });
      }
    })
    .then(() => {
      const action = isEdit ? 'updated' : 'saved';
      enqueueSnackbar(`Your spot was ${action}`);
      handleClose();
    })
    .catch((error) => {
      const action = isEdit ? 'updating' : 'saving';
      enqueueSnackbar(`There was an error ${action} your spot`);
      handleClose();
    });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // TODO(ramin): not sure why I have to explicitly call handleClick will null
  // b/c I used a default parameter (I used a default paramater of spot = null
  // so I would expect it to work without that, but it doesn't) hmmm...
  return (
    <div>
      <Button color="primary" onClick={() => handleClick(null)} variant="contained">
        Add Spot
      </Button>
      <Dialog open={isOpen} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" disableTypography>
          <Typography variant='h4'>{isEdit ? 'Edit' : 'Add'} Spot</Typography>
          </DialogTitle>
        <DialogContent>
          <form
            autoComplete="off"
            noValidate
          >
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={handleChange}
                  required
                  value={formState.values.name}
                  error={hasError('name')}
                  helperText={
                    hasError('name') ? formState.errors.name[0] : null
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Region"
                  margin="dense"
                  name="region"
                  onChange={handleChange}
                  value={formState.values.region}
                  error={hasError('region')}
                  helperText={
                    hasError('region') ? formState.errors.region[0] : null
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.isDefault}
                      onChange={handleChange}
                      color="primary"
                      name="isDefault"
                    />
                  }
                  label="Make this my default spot for logging sessions"
                />
             </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!formState.isValid || formState.isSubmitting}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditSpot.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  spot: PropTypes.object
};

export default EditSpot;
