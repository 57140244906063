import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ render: Render = null, children, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          if (Render) {
            return Render(props);
          } else {
            return children;
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: "/log-in",
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool,
  path: PropTypes.string
};

export default PrivateRoute;
