import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import firebase from 'firebase/app';
import { Link as RouterLink } from 'react-router-dom';

import {
  Avatar, Link,
} from '@material-ui/core';
import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: props => props.size,
    height: props => props.size,
    backgroundColor: theme.palette.primary.main,
    fontSize: props => theme.typography.pxToRem(props.size / 2),
  },
}));

const UserAvatar = props => {
  const { profilePictureSuffix, src, userId, userName, linkToProfile } = props;
  const classes = useStyles(props);

  let avatarSrc;
  if (src) {
    avatarSrc = src;
  } else {
    avatarSrc = `https://firebasestorage.googleapis.com/v0/b/thrashtown-17146.appspot.com/o/${userId}%2Fprofile?alt=media${profilePictureSuffix}`;
  }

  const avatar = (
      <Avatar
        alt={userName}
        className={classes.avatar}
        src={avatarSrc}
      >
        {getInitials(userName)}
      </Avatar>
  );

  if (linkToProfile) {
    // TODO(ramin): consolidate this somewhere in a util (this is done in several places)
    const isCurrentUser = userId === firebase.auth().currentUser.uid;
    const to = isCurrentUser ? '/profile' : `/profile/${userId}`;

    return (
      <Link component={RouterLink} to={to}>
        {avatar}
      </Link>
    );
  }

  return avatar;
};

UserAvatar.propTypes = {
  userName: PropTypes.string,
  userId: PropTypes.string,
  linkToProfile: PropTypes.bool,
  profilePictureSuffix: PropTypes.string,
  size: PropTypes.number,
  src: PropTypes.string,
};

UserAvatar.defaultProps = {
  linkToProfile: true,
  profilePictureSuffix: '',
  size: 40,
  src: null,
};

export default UserAvatar;
