import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';
import { boardLabel } from 'views/Boards/Boards';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';

import {
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Actions = ({groupBy, onChange}) => {
  return (
    <ToggleButtonGroup value={groupBy} onChange={onChange} exclusive size="small">
      <ToggleButton value="board" >
        Board
      </ToggleButton>
      <ToggleButton value="type" >
        Type
      </ToggleButton>
    </ToggleButtonGroup>
    );
  };

  const BoardsChart = props => {
    const { className, boards, types, resources, hasEverLogged, ...rest } = props;
    const classes = useStyles();

    const [state, setState] = useState({
      groupBy: 'board',
    });

    const { groupBy } = state;

    const handleGroupingChange = (_event, groupBy) => {
      // The toggle button group let's you deselect, so prevent deselection
      if (groupBy) {
        setState(state => ({
          ...state,
          groupBy,
        }));
      }
    };

    let transformedData;
    if (groupBy === 'board') {
      transformedData = map(boards, (count, id) => {
        const board = resources[id];
        const label = boardLabel(board);

        return {
          count,
          label,
          type: board.type,
        };
      });
    } else if (groupBy === 'type') {
      const sumByType = reduce(boards, (result, count, id) => {
        const { type } = resources[id];
        if (type) {
          result[type] = (result[type] || 0) + count;
        }

        return result;
      }, {});

      transformedData = map(sumByType, (count, label) => ({ count, label }));
    }

    const data = orderBy(transformedData, 'count', 'desc');

    const chartData = {
      labels: data.map(i => i.label),
      datasets: [
        {
          label: 'Sessions',
          backgroundColor: palette.primary.main,
          data: data.map(i => i.count),
        }
      ]
    };

    const boardCount = data.length;
    const chartHeight = boardCount * 23 + 23

    const options = {
      indexAxis: 'y',
      plugins: {
        legend: {
          display: false
        },
        // TODO(ramin): I'd like to use the following option to be more like SessionsChart, but they
        // don't seem to work well for horizontal bar charts, revisit :\
        tooltip: {
          mode: 'index',
          intersect: false,
          axis: 'y',
          callbacks: {
            afterLabel: (context) => {
              // TODO(ramin): if I could instead configure the chart to pass in an object
              // for the data with x: y: and type:, I think I could get this directly from
              // the context.  It's weird we have to look it up from the data by index TBH but it works.
              return get(data, [context.dataIndex, 'type'], null);
            },
          },
        },
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          offset: true,
          ticks: {
            fontColor: palette.text.secondary
          },
          grid: {
            display: false,
            drawBorder: false
          }
        },
        x: {
          beginAtZero: true,
          min: 0,
          ticks: {
            fontColor: palette.text.secondary,
            precision: 0, // Prevent decimal ticks
          },
          grid: {
            borderDash: [2],
            borderDashOffset: [2],
            color: palette.divider,
            drawBorder: false,
            drawTicks: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: palette.divider
          }
        }
      }
    };

    return (
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          title="Boards"
          action={<Actions groupBy={groupBy} onChange={handleGroupingChange} />}
        />
        <Divider />
        <CardContent>
          {isEmpty(data) &&
            <Typography variant="body1" color="textSecondary">
              {hasEverLogged && <span>No data</span>}
              {!hasEverLogged && <span>No session logged</span>}
            </Typography>
          }
          {!isEmpty(data) &&
            <div className={classes.chartContainer} style={{ height: chartHeight}}>
              <Bar data={chartData} options={options} />
            </div>
          }
        </CardContent>
        <Divider />
      </Card>
      );
    };

    BoardsChart.propTypes = {
      className: PropTypes.string,
      boards: PropTypes.object,
      types: PropTypes.object,
      resources: PropTypes.object,
      hasEverLogged: PropTypes.bool,
    };

    export default BoardsChart;
