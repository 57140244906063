import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core';

import firebase from 'firebase/app';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 254
    }
  }
};

const initialState = {
  isValid: false,
  isSubmitting: false,
  touched: {},
  errors: {},
  values: {
    email: ''
  },
};

const AddFriend = props => {
  const functions = firebase.functions();

  const { handleClick, handleClose, isOpen } = props;

  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    if (isOpen) {
      // Only reset props on open. This ensures that values in the form
      // aren't cleared as the dialog close is still animating (which would be ugly).
      setFormState(formState => (initialState))
    }
  }, [isOpen])

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    // TODO(ramin): revisit this or at least share this code amoung the forms
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSave = event => {
    event.preventDefault();
    closeSnackbar();

    setFormState(formState => ({
      ...formState,
      isSubmitting: true
    }));

    functions.httpsCallable('addFriend')({email: formState.values.email})
      .then(() => {
        handleClose();
        enqueueSnackbar('Friend request sent!');
      })
      .catch((error) => {
        const displayMessage = get(error, 'details.displayMessage')
        const errorMessage = displayMessage ? get(error, 'message') : 'Something went wrong'

        setFormState(formState => ({
          ...formState,
          isValid: false,
          errors: {email: [errorMessage]}
        }));
      })
      .finally(() => {
        setFormState(formState => ({
          ...formState,
          isSubmitting: false
        }));
      });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // TODO(ramin): not sure why I have to explicitly call handleClick will null
  // b/c I used a default parameter (I used a default paramater of board = null
  // so I would expect it to work without that, but it doesn't) hmmm...
  return (
    <div>
      <Button color="primary" onClick={() => handleClick(null)} variant="contained">
        Invite Friend
      </Button>
      <Dialog open={isOpen} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" disableTypography>
          <Typography variant='h4'>Invite Friend</Typography>
          </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Invite another thrashtown user to connect. Once connected, you can
            view each other's sessions and tag each other when you surf together.
          </DialogContentText>
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSave}
          >
            <TextField
              fullWidth
              label="Email *"
              margin="dense"
              name="email"
              type="text"
              onChange={handleChange}
              value={formState.values.email}
              error={hasError('email')}
              helperText={
                hasError('email') ? formState.errors.email[0] : null
              }
              variant="outlined"
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          disabled={formState.isSubmitting}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!formState.isValid || formState.isSubmitting}
          >
            Invite
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddFriend.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default AddFriend;
