import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/app';
import { Link as RouterLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  Popover,
  Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Rating from '@material-ui/lab/Rating';
import { boardLabel } from 'views/Boards/Boards';
import { spotLabel } from 'views/Spots/Spots';
import { Context } from '../Store';
import UserAvatar from 'components/UserAvatar';
import PersonIcon from '@material-ui/icons/Person';
import UserNameProfileLink from 'components/UserNameProfileLink';

const StyledRating = withStyles(({ palette }) => ({
  iconFilled: {
    color: palette.secondary.main,
  },
}))(Rating);

const useStyles = makeStyles(theme => ({
  description: {
    paddingTop: theme.spacing(1),
  },
  spot: {
    display: 'flex',
    verticalAlign: 'middle',
  },
}));

const SessionCard = props => {
  const classes = useStyles();
  const [store, ] = useContext(Context);

  const { isNameClickable = true, showAvatar = true, sessionId, session } = props;

  let labelForBoard;
  const board  = session.board
  if (board) {
    labelForBoard = boardLabel(board, true);
  }

  const { spot } = session;
  const hasSpot = !!spot;
  const hasFriends = !!session.friends && !!session.friends.length
  const belongsToCurrentUser = session.userId === firebase.auth().currentUser.uid;

  const [state, setState] = useState({
    isOpenMoreActionsPopover: false,
    popoverAnchorEl: null,
  });

  const {
    isOpenMoreActionsPopover,
    popoverAnchorEl,
  } = state;

  const handleMoreActionsClick = event => {
    setState(state => ({ ...state, popoverAnchorEl: event.currentTarget, isOpenMoreActionsPopover: true }));
  };

  const handlePopoverClose = () => {
    setState(state => ({
      ...state,
      popoverAnchorEl: null,
      isOpenMoreActionsPopover: false,
    }));
  };

  // TODO(ramin): make a shared util for this
  const timeZone = session.timeZone || 'America/Los_Angeles'
  const sessionDate = DateTime.fromJSDate(session.sessionDate.toDate(), { zone: timeZone })
  const dateFormat = Object.assign(DateTime.DATETIME_MED, {
    weekday: 'short',
  })
  const formattedDate = sessionDate.toLocaleString(dateFormat);

  // This is an optimization: the main point of this is to update the
  // session cards' avatar for the current user if they update their profile
  // picture.  Otherwise, it would rely on them reloading the route.
  const profilePictureSuffix = belongsToCurrentUser ? store.profilePictureSuffix : '';

  return (
    <Card>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item container xs={12} sm spacing={2}>
            {showAvatar &&
              <Grid item>
                <UserAvatar
                  userName={session.userName}
                  userId={session.userId}
                  profilePictureSuffix={profilePictureSuffix}
                />
              </Grid>
            }
            <Grid item>
              <Typography variant="h5">
                {isNameClickable ?
                  <UserNameProfileLink userId={session.userId} name={session.userName} />
                :
                  session.userName
                }
              </Typography>
              <Typography variant="body2">
                {formattedDate} • <strong>{session.duration} {session.duration === '1' ? 'hr' : 'hrs'}</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {hasSpot && (
              <span className={classes.spot}>
                <Chip
                  size="small"
                  label={spotLabel(spot)}
                  variant="outlined"
                  icon={<LocationOnIcon />}
                />
              </span>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={1} className={classes.description}>
          {labelForBoard &&
            <Grid item>
                <Chip
                  size="small"
                  label={labelForBoard}
                  variant="outlined"
                />
            </Grid>
          }
          {hasFriends && session.friends.map(friend =>
            <Grid item key={friend.id}>
              <Chip
                size="small"
                label={friend.name}
                color="primary"
                variant="outlined"
                icon={<PersonIcon />}
              />
            </Grid>
          )}
          {session.description &&
            <Grid item xs={12}>
              <Typography variant="body1" color="textSecondary">
                {session.description}
              </Typography>
            </Grid>
          }
          <Grid container item justify="space-between">
            <Grid container item direction="column" xs>
                {[
                  ['Q', session.waveQuality],
                  ['H', session.hollowness],
                  ['C', session.crowdedness],
                  ['F', session.funFactor],
                ].map(([label, rating], idx) => (
                  <Grid key={idx} item container spacing={1}>
                    <Grid item>
                      <Typography variant="overline">{label}</Typography>
                    </Grid>
                    <Grid item>
                      <StyledRating defaultValue={rating} size="small" readOnly />
                    </Grid>
                  </Grid>
                ))}
            </Grid>
            { belongsToCurrentUser &&
              <Grid container item xs direction="column" justify="flex-end" alignContent="flex-end">
                <Grid item>
                  <IconButton onClick={handleMoreActionsClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Popover
                      id={`${sessionId}-more-actions-popover`}
                      open={isOpenMoreActionsPopover}
                      anchorEl={popoverAnchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                    <List dense={true}>
                      <ListItem dense={true}>
                        <IconButton aria-label="edit" component={RouterLink} to={`/surf/${sessionId}`}>
                          <EditTwoToneIcon fontSize="small" />
                        </IconButton>
                      </ListItem>
                    </List>
                    </Popover>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

SessionCard.propTypes = {
  sessionId: PropTypes.string.isRequired,
  session: PropTypes.object.isRequired,
  isNameClickable: PropTypes.bool,
  showAvatar: PropTypes.bool,
};

export default SessionCard;
