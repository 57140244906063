const initialState = {
  // This is useful to have in the global store because it effectively serves to
  // invalidate the browser's image cache on updating the current user's profile photo.
  // By keeping this in the global store it also permits us to do it selectively
  // thereby not refetching from the server constantly if we chose an approach
  // where this query param were constantly changing.
  profilePictureSuffix: '',

  // The only reason this is in the store is that the name is always shown in the sidebar
  // and it can be edited from the accounts page. So if we edit it from account, we can't
  // rely on a component reloading to updated the name shown in the sidebar.  It was either
  // this or wiring through some callbacks. Neither is ideal.
  userName: '',
};

const Reducer = (state, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return initialState;
    case 'PROFILE_PICTURE_UPDATED':
      return {
        ...state,
        profilePictureSuffix: action.payload
      };
    case 'USER_NAME_UPDATED':
      return {
        ...state,
        userName: action.payload
      };
    default:
      return state;
  }
};

export default Reducer;
export { initialState };
