import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { boardLabel } from 'views/Boards/Boards';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  count: {
    textAlign: 'right'
  }
}));

const TopBoardCard = props => {
  const { count, name, lengthFeet, lengthInches, type } = props;

  const classes = useStyles();

  // The may be no board
  const label = !!name ? boardLabel({name, lengthFeet, lengthInches, type}, true) : null;

  return (
    <Grid
      container
      item
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={10}>
        <Typography
          variant="overline"
        >
          Top board
        </Typography>
        <Typography variant="body1">{!!label ? label : <span>&mdash;</span>}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h3" className={classes.count}>{count}</Typography>
      </Grid>
    </Grid>
  );
};

TopBoardCard.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  name: PropTypes.string,
  lengthFeet: PropTypes.string,
  lengthInches: PropTypes.string,
  type: PropTypes.string,
};

export default TopBoardCard;
