import palette from 'theme/palette';

export const options = {
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      // borderWidth: 1,
      // borderColor: palette.divider,
      // backgroundColor: palette.white,
      // titleFontColor: palette.text.primary,
      // bodyFontColor: palette.text.secondary,
      // footerFontColor: palette.text.secondary
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      type: 'time',
      time: {
        tooltipFormat: 'MMM yyyy',
        minUnit: 'month',
      },
      offset: true,
      ticks: {
        fontColor: palette.text.secondary
      },
      grid: {
        display: false,
        drawBorder: false
      }
    },
    y: {
      beginAtZero: true,
      min: 0,
      ticks: {
        fontColor: palette.text.secondary,
        precision: 0, // Prevent decimal ticks
      },
      grid: {
        borderDash: [2],
        borderDashOffset: [2],
        color: palette.divider,
        drawBorder: false,
        drawTicks: false,
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
        zeroLineColor: palette.divider
      }
    }
  }
};
