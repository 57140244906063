import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import reduce from 'lodash/reduce';


import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';

import {
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Actions = ({ groupBy, onChange }) => {
  return (
    <ToggleButtonGroup value={groupBy} onChange={onChange} exclusive size="small">
      <ToggleButton value="spot" >
        Spot
      </ToggleButton>
      <ToggleButton value="region" >
        Region
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const SpotsChart = props => {
  const classes = useStyles();
  const { className, spots, regions, resources, hasEverLogged, ...rest } = props;

  const [state, setState] = useState({
    groupBy: 'spot',
  });

  const { groupBy } = state;

  const handleGroupingChange = (_event, groupBy) => {
    // The toggle button group let's you deselect, so prevent deselection
    if (groupBy) {
      setState(state => ({
        ...state,
        groupBy,
      }));
    }
  };

  let transformedData;
  if (groupBy === 'spot') {
    transformedData = map(spots, (count, id) => {
      const { name: label, region } = resources[id];

      return {
        count,
        label,
        region,
      }
    });
  } else if (groupBy === 'region') {
    const sumByRegion = reduce(spots, (result, count, id) => {
      const { region } = resources[id];
      if (region) {
        result[region] = (result[region] || 0) + count;
      }

      return result;
    }, {});

    transformedData = map(sumByRegion, (count, label) => ({ count, label }));
  }


  const data = orderBy(transformedData, 'count', 'desc')

  const chartData = {
    labels: data.map(i => i.label),
    datasets: [
      {
        label: 'Sessions',
        backgroundColor: palette.primary.main,
        data: data.map(i => i.count),
      }
    ]
  };

  const spotCount = data.length;
  const chartHeight = spotCount * 23 + 23

  const options = {
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        axis: 'y',
        callbacks: {
          afterLabel: (context) => {
            // TODO(ramin): if I could instead configure the chart to pass in an object
            // for the data with x: y: and type:, I think I could get this directly from
            // the context.  It's weird we have to look it up from the data by index TBH but it works.
            return get(data, [context.dataIndex, 'region'], null);
          },
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        offset: true,
        ticks: {
          fontColor: palette.text.secondary
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      x: {
        beginAtZero: true,
        min: 0,
        ticks: {
          fontColor: palette.text.secondary,
          precision: 0, // Prevent decimal ticks
        },
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          drawTicks: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Spots"
        action={<Actions groupBy={groupBy} onChange={handleGroupingChange} />}
      />
      <Divider />
      <CardContent>
        {isEmpty(data) &&
          <Typography variant="body1" color="textSecondary">
            {hasEverLogged && <span>No data</span> }
            {!hasEverLogged && <span>No session logged</span>}
          </Typography>
        }
        {!isEmpty(data) &&
          <div className={classes.chartContainer} style={{ height: chartHeight }}>
            <Bar data={chartData} options={options} />
          </div>
        }
      </CardContent>
      <Divider />
    </Card>
  );
};

SpotsChart.propTypes = {
  className: PropTypes.string,
  spots: PropTypes.object,
  regions: PropTypes.object,
  resources: PropTypes.object,
  hasEverLogged: PropTypes.bool,
};

export default SpotsChart;
