import React, { useState, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/styles';
import { signIn } from '../../helpers/auth'
import {
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import Footer from '../../layouts/Footer'

import firebase from 'firebase/app';

const schema = {
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  submitButton: {
    margin: theme.spacing(2, 0)
  }
}));

const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

const ResetPassword = props => {
  const { history } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const location = useLocation();
  const queryParams = useQueryParams();
  const resetToken = queryParams.get('oobCode');

  const classes = useStyles();

  const [state, setState] = useState({
    isValid: false,
    isDisabled: false,
    values: {},
    touched: {},
    errors: {}
  });

  const validateForm = () => {
    const errors = validate(state.values, schema);

    setState(state => ({
      ...state,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  };

  const validateRoute = () => {
    const resetToken = queryParams.get('oobCode');
    if (!resetToken) {
      // Firebase will provide this as a query param, and it's necessary for this component.
      // Simply redirect rather than show some kind of error message.
      history.replace('/');
    }
  };

  useEffect(validateRoute, []);
  useEffect(validateForm, [state.values]);

  const handleChange = event => {
    event.persist();

    setState(state => ({
      ...state,
      values: {
        ...state.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...state.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleReset = event => {
    event.preventDefault();

    event.preventDefault();
    closeSnackbar();
    setState(state => ({
      ...state,
      isDisabled: true
    }));

    const { password } = state.values;

    return firebase.auth().verifyPasswordResetCode(resetToken).then((email) => {
      return firebase.auth().confirmPasswordReset(resetToken, password).then((resp) => {
        return signIn(email, password, location);
      }).catch((error) => {
        // The code might have expired or the password is too weak.
        if (get(error, 'code') === 'auth/weak-password') {
          enqueueSnackbar(error.message);
        } else {
          console.error('Error occured during confirmation', error);
          enqueueSnackbar('There was a problem resetting your password.  Please request a new password reset link.', { action });
        }
      });
    }).catch((error) => {
      console.error('Invalid or expired reset token', error);
      enqueueSnackbar('Invalid or expired reset token. Please request a new password reset link.', { action });
    }).finally(() => {
      setState(state => ({
        ...state,
        isDisabled: false
      }));
    });
  };

  const hasError = field =>
    state.touched[field] && state.errors[field] ? true : false;

  const action = key => (
    <Button
      color="secondary"
      onClick={() => {
        history.push('/forgot-password');
        closeSnackbar(key);
      }}>
      Send a New Link
    </Button>
  );

  return (
    <div className={classes.root}>
       <div className={classes.content}>
        <div className={classes.contentBody}>
          <form
            className={classes.form}
            onSubmit={handleReset}
          >
            <Typography
              className={classes.title}
              variant="h2"
            >
              Reset Your Password
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
            >
              Enter your new password and login
             </Typography>
            <TextField
              className={classes.textField}
              error={hasError('password')}
              fullWidth
              helperText={
                hasError('password') ? state.errors.password[0] : null
              }
              label="Password *"
              name="password"
              onChange={handleChange}
              type="password"
              value={state.values.password || ''}
              variant="outlined"
            />
            <Button
              className={classes.submitButton}
              color="primary"
              disabled={!state.isValid || state.isLoading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Reset Password and Login
            </Button>
            <Footer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);
