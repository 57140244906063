import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Typography,
} from '@material-ui/core';
import firebase from 'firebase/app';
import { Context } from '../../../../../../Store';
import UserAvatar from 'components/UserAvatar';
import UserNameProfileLink from 'components/UserNameProfileLink';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  name: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  }
}));

// When the profile photo is updated, we use React's built in global state
// store (the Context API) to update a special suffix we can use as a fake query
// param to effectively force a new image to be loaded by the browser
const Profile = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const currentUser = firebase.auth().currentUser;

  const [store,] = useContext(Context);

  // The only reason we use the store for this is because if the user edits their
  // user name from the accounts page, we want the sidebar to be updated immediately.
  const userName = store.userName || currentUser.displayName;

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <UserAvatar
        userName={userName}
        userId={currentUser.uid}
        profilePictureSuffix={store.profilePictureSuffix}
        size={60}
      />
      <Typography
        className={classes.name}
        variant="h5"
      >
        <UserNameProfileLink name={userName} />
      </Typography>
      <Typography variant="body2">{currentUser.email}</Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
