import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import pick from 'lodash/pick';
import some from 'lodash/some';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import {
  numericValidator,
} from '../../helpers';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import firebase from 'firebase/app';

const filter = createFilterOptions();

const suggestedTypes = [
  { value: 'Shortboard', label: 'Shortboard' },
  { value: 'Fish', label: 'Fish' },
  { value: 'Funboard', label: 'Funboard' },
  { value: 'Longboard', label: 'Longboard' },
  { value: 'Step-up', label: 'Step-up' },
  { value: 'Gun', label: 'Gun' },
  { value: 'Bodyboard', label: 'Bodyboard' },
  { value: 'Standup Paddle Board', label: 'Standup Paddle Board' },
];

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 254
    }
  },
  lengthFeet: {
    numericality: numericValidator(0, 30, true),
  },
  lengthInches: {
    numericality: numericValidator(0, 11, true),
  },
};

const initialState = {
  isValid: false,
  isSubmitting: false,
  touched: {},
  errors: {},
  values: {
    lengthFeet: '',
    lengthInches: '',
    name: '',
    type: '',
    isDefault: false,
  },
};

const EditBoard = props => {
  const db = firebase.firestore();
  const userId = firebase.auth().currentUser.uid;

  const { board, handleClick, handleClose, isOpen } = props;
  const isEdit = !!board;

  const [formState, setFormState] = useState(initialState);

  useEffect(() => {
    if (isOpen) {
      // Only reset props on open. This ensures that values in the form
      // aren't cleared as the dialog close is still animating (which would be ugly).
      if (board) {
        setFormState(formState => ({
          ...initialState,
          values: ({...formState.values, ...board.data()})
        }));
      } else {
        setFormState(formState => (initialState))
      }
    }
  }, [isOpen, board])

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    if (isFunction(event.persist)) {
      // Because of how pass some values manually to handleChange we need to
      // check if there is an event or not. Not ideal, but the upside is we
      // still have a unified method for handling changes.
      event.persist();
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSave = event => {
    event.preventDefault();
    closeSnackbar();

    setFormState(formState => ({
      ...formState,
      isSubmitting: true
    }));

    const formValues = pick(formState.values, [
      'lengthFeet',
      'lengthInches',
      'name',
      'type',
      'isDefault'
      ]);

    const boardToSave = Object.assign(formValues, { isArchived: false });

    const boardCollection = db.collection('users')
        .doc(userId)
        .collection('boards');

    let promise;
    if (isEdit) {
      promise = boardCollection
        .doc(board.id)
        .update(boardToSave)
        .then(() => board.id);
    } else {
      promise = boardCollection
      .add(boardToSave)
      .then((board) => board.id);
    }

    return promise.then(updatedBoardId => {
      // If this is the default board, reset the other default board if there
      // is one.
      // Note: in theory there should ever be at most only one default board,
      // so this loop might be somewhat unecessary, but safe to do in case somehow
      // there are multiple default boards.
      if (formState.values.isDefault) {
        return boardCollection.where('isDefault', '==', true)
          .get()
          .then(querySnapshot => {
            const batch = db.batch()
            let counter = 0;
            querySnapshot.forEach(doc => {
              const boardId = doc.id;
              if (boardId !== updatedBoardId) {
                batch.update(boardCollection.doc(boardId), {isDefault: false});
                counter++;
              }
            });
            if (counter) {
              return batch.commit();
            }
          });
      }
    })
    .then(() => {
      const action = isEdit ? 'updated' : 'saved';
      enqueueSnackbar(`Your board was ${action}`);
      handleClose();
    })
    .catch((error) => {
      const action = isEdit ? 'updating' : 'saving';
      enqueueSnackbar(`There was an error ${action} your board`);
      handleClose();
    });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  // TODO(ramin): not sure why I have to explicitly call handleClick will null
  // b/c I used a default parameter (I used a default paramater of board = null
  // so I would expect it to work without that, but it doesn't) hmmm...
  return (
    <div>
      <Button color="primary" onClick={() => handleClick(null)} variant="contained">
        Add Board
      </Button>
      <Dialog open={isOpen} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" disableTypography>
          <Typography variant='h4'>{isEdit ? 'Edit' : 'Add'} Board</Typography>
        </DialogTitle>
        <DialogContent>
          <form
            autoComplete="off"
            noValidate
          >
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Name"
                  margin="dense"
                  name="name"
                  onChange={handleChange}
                  required
                  value={formState.values.name}
                  error={hasError('name')}
                  helperText={
                    hasError('name') ? formState.errors.name[0] : null
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <Autocomplete
                  value={formState.values.type}
                  onChange={(event, newValue) => {
                    if (get(newValue, 'inputValue')) {
                      // Create a new value from the user input
                      handleChange({ target: { value: newValue.inputValue, name: 'type' } });
                    } else {
                      // This is from either deleting the value or setting the value from the suggested options
                      const value = get(newValue, 'value', '');
                      handleChange({ target: { value, name: 'type' } });
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    const { inputValue } = params;
                    if (inputValue !== '' && !some(options, {label: inputValue})) {
                      filtered.push({
                        inputValue: inputValue,
                        label: `Add: "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text"
                  options={suggestedTypes}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.label;
                  }}
                  renderOption={(option) => option.label}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params}
                      fullWidth
                      placeholder="Select one or add your own"
                      margin="dense"
                      error={hasError('type')}
                      helperText={
                        hasError('type') ? formState.errors.type[0] : null
                      }
                      variant="outlined"
                      label="Type" />
                  )}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Length (feet)"
                  margin="dense"
                  name="lengthFeet"
                  type="number"
                  onChange={handleChange}
                  value={formState.values.lengthFeet}
                  error={hasError('lengthFeet')}
                  helperText={
                    hasError('lengthFeet') ? formState.errors.lengthFeet[0] : null
                  }
                  variant="outlined"
                  inputProps={{ min: "0", max: "30", step: "1" }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Length (inches)"
                  margin="dense"
                  name="lengthInches"
                  type="number"
                  onChange={handleChange}
                  value={formState.values.lengthInches}
                  error={hasError('lengthInches')}
                  helperText={
                    hasError('lengthInches') ? formState.errors.lengthInches[0] : null
                  }
                  variant="outlined"
                  inputProps={{ min: "0", max: "12", step: "1" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.isDefault}
                      onChange={handleChange}
                      color="primary"
                      name="isDefault"
                    />
                  }
                  label="Make this my default board for logging sessions"
                />
             </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!formState.isValid || formState.isSubmitting}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

EditBoard.propTypes = {
  handleClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  board: PropTypes.object
};

export default EditBoard;
