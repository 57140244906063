import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
  },
  count: {
    textAlign: 'right'
  }
}));

const TopFriendCard = props => {
  const { count, name } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      item
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={10}>
        <Typography
          variant="overline"
        >
          Top friend
        </Typography>
        <Typography variant="body1">{!!name ? name : <span>&mdash;</span>}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h3" className={classes.count}>{count}</Typography>
      </Grid>
    </Grid>
  );
};

TopFriendCard.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  name: PropTypes.string,
  friendsSince: PropTypes.string,
};

export default TopFriendCard;
