import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Topbar } from './components';

import { TOPBAR_HEIGHT_LG, TOPBAR_HEIGHT_SM } from '../../layouts/Main/Main'
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: TOPBAR_HEIGHT_SM,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: TOPBAR_HEIGHT_LG
    },
  },
}));

const Minimal = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <main>{children}</main>
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
