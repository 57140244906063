import React, { useState, useEffect } from 'react';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  TextField,
  Typography
} from '@material-ui/core';

import firebase from 'firebase/app';
import Footer from '../../layouts/Footer'

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 254
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  submitButton: {
    margin: theme.spacing(2, 0)
  }
}));

const ForgotPassword = props => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    isSubmitting: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleReset = event => {
    event.preventDefault();

    event.preventDefault();
    closeSnackbar();
    setFormState(formState => ({
      ...formState,
      isSubmitting: true
    }));

    const {email} = formState.values;
    firebase.auth().sendPasswordResetEmail(email).then((value) => {
      enqueueSnackbar('You will receive an email with a link to reset your password.');
    }).catch((reason) => {
      let message = 'Something went wrong, please try again';
      if (get(reason, 'code') === 'auth/user-not-found') {
        message = 'There is no registered user corresponding with this email.';
      }

      enqueueSnackbar(message);
      setFormState(formState => ({
          ...formState,
          isSubmitting: false
        }));
      return;
    });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
       <div className={classes.content}>
        <div className={classes.contentBody}>
          <form
            className={classes.form}
            onSubmit={handleReset}
          >
            <Typography
              className={classes.title}
              variant="h2"
            >
              Forgot Your Password?
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
            >
              Enter your email address and we will send you a link to reset your password.
             </Typography>
            <TextField
              className={classes.textField}
              error={hasError('email')}
              fullWidth
              helperText={
                hasError('email') ? formState.errors.email[0] : null
              }
              label="Email *"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.values.email || ''}
              variant="outlined"
            />
            <Button
              className={classes.submitButton}
              color="primary"
              disabled={!formState.isValid || formState.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Send Password Reset Email
            </Button>
            <Footer />
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
