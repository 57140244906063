import React, { useContext, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Button,
  Typography
} from '@material-ui/core';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import Alert from '@material-ui/lab/Alert';
import firebase from 'firebase/app';
import { Context } from '../../Store'
import UserAvatar from 'components/UserAvatar';

const initialState = {
  isOpenEditPhoto: false,
  file: null,
  isPhotoTooBig: false,
  isSaving: false,
};

const ProfilePicture = () => {
  const currentUser = firebase.auth().currentUser;
  const userId = currentUser.uid;
  const [state, setState] = useState(initialState);
  const { enqueueSnackbar } = useSnackbar();
  const [store, dispatch] = useContext(Context);
  const { profilePictureSuffix } = store

  // Reset state on *open* instead of close because there is a
  // delay / animation on close which would result in an ugly blip
  const openEditPhoto = () => {
    setState({ ...initialState, isOpenEditPhoto: true });
  };

  const closeEditPhoto = () => {
    setState(state => ({ ...state, isOpenEditPhoto: false }));
  };

  const handleUpload = (event) => {
    const file = event.target.files[0]
    const sizeInMb = file.size / 1024 / 1024
    if (sizeInMb > 2) {
      setState(state => ({
        ...state,
        isPhotoTooBig: true,
      }));
    } else {
      setState(state => ({
        ...state,
        isPhotoTooBig: false,
        file: event.target.files[0],
      }));
    }
  };

  const handleSavePhoto = event => {
    event.preventDefault();

    setState(state => ({
      ...state,
      isSaving: true,
    }));

    const storageRef = firebase.storage().ref(userId + '/profile');
    return storageRef.put(state.file)
      .then(() => {
        enqueueSnackbar('Your profile picture was updated');
        closeEditPhoto();
        dispatch({ type: 'PROFILE_PICTURE_UPDATED', payload: `&${Date.now()}` });
      })
      .catch(() => enqueueSnackbar('There was a problem updating your profile image'))
      .finally(() => {
        setState(state => ({
          ...state,
          isSaving: false,
        }));
      });
  };


  const {
    isOpenEditPhoto,
    file,
    isSaving,
    isPhotoTooBig,
  } = state;

  const hasUpload = !!file;
  const updateSrc = hasUpload ? URL.createObjectURL(file) : null;

  return (
    <Grid item md={6} xs={12}>
      <Card>
        <CardHeader
          title="Profile Picture"
          action={
            <IconButton
              disabled={isSaving}
              onClick={openEditPhoto}
              size="small"
            >
              <EditTwoToneIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <UserAvatar
                linkToProfile={false}
                userName={currentUser.displayName}
                userId={currentUser.uid}
                profilePictureSuffix={profilePictureSuffix}
                size={120}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog open={isOpenEditPhoto} onClose={closeEditPhoto} fullWidth>
        <DialogTitle id="form-dialog-title" disableTypography>
          <Typography variant='h4'>Update Profile Picture</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <UserAvatar
                linkToProfile={false}
                userName={currentUser.displayName}
                userId={currentUser.uid}
                profilePictureSuffix={profilePictureSuffix}
                src={updateSrc}
                size={240}
              />
            </Grid>
            {isPhotoTooBig &&
              <Grid
                item
                container
                direction="column"
                alignItems="stretch"
              >
                <Alert severity="error">Sorry, the max size for your profile picture is 2MB</Alert>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            disabled={isSaving}
            onClick={closeEditPhoto}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            component="label"
            disabled={isSaving}
          >
            Choose Photo
                <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleUpload}
            />
          </Button>
          {hasUpload &&
            <Button
              color="primary"
              variant="contained"
              disabled={isSaving}
              onClick={handleSavePhoto}
            >
              Save
            </Button>
          }
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default ProfilePicture;
