import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SessionCard } from 'components';
import firebase from 'firebase/app';
import difference from 'lodash/difference';
import every from 'lodash/every';
import get from 'lodash/get';

import {
  Box,
  CircularProgress,
  Grid,
} from '@material-ui/core';

const FilteredSessions = props => {
  const db = firebase.firestore();
  const { surfSummaries, userId } = props;

  const [state, setState] = useState({
    sessionCache: {},
  });

  const {
    sessionCache,
  } = state;

  const fetchData = () => {
    const ids = surfSummaries.map(s => s.id)
    const newIds = difference(ids, Object.keys(sessionCache))

    if (!newIds.length) {
      return;
    }

    // First update the state to reflect the set of new surfs that will be fetched
    // The idea is to guard against successive fast updates to this component from
    // the user clicking around in the profile so we don't fetch the same session
    // a bunch of times.
    setState(state => {
      const additionsToCache = {}
      newIds.forEach(id => additionsToCache[id] = { isLoading: true });

      return ({
        ...state,
        sessionCache: {
          ...state.sessionCache,
          ...additionsToCache,
        },
      });
    });

    const fetched = newIds.map(id => {
      return db.collection('users')
        .doc(userId)
        .collection('surfs')
        .doc(id)
        .get();
    });

    Promise.all(fetched).then(results => {
      const additionToCache = {};
      results.forEach(doc => {
        if (!doc.exists) {
          // There could be a race between deleting a session and the surf summaries being updated
          additionToCache[doc.id] = { isLoading: false };
        } else {
          additionToCache[doc.id] = {
            isLoading: false,
            data: doc.data(),
          };
        }
      });

      setState(state => {
        return ({
          ...state,
          sessionCache: {
            ...state.sessionCache,
            ...additionToCache,
          },
        });
      });
    });
  };

  useEffect(fetchData, [surfSummaries]);

  const isLoading = !!surfSummaries.length && every(surfSummaries, s => get(sessionCache[s.id], 'isLoading', true));

  if (isLoading) {
    return (
      <Grid container justify="center">
        <Box p={5}>
          <CircularProgress />
        </Box>
      </Grid>
    );
  }

  const foundSurfs = [];
  surfSummaries.forEach(({id}) => {
    // There could be a race between deleting a session and the surf summaries being updated
    if (get(sessionCache[id], 'data')) {
      foundSurfs.push({
        id: id,
        data: sessionCache[id].data
      });
    }
  });

  return (
    foundSurfs.map(({id, data}) => {
      return (
        <Grid item xs={12} key={id}>
          <SessionCard sessionId={id} session={data} isNameClickable={false} showAvatar={false}/>
        </Grid>
      );
    })
  );
};

FilteredSessions.propTypes = {
  hasEverLogged: PropTypes.bool,
  surfSummaries: PropTypes.array,
  userId: PropTypes.string,
};

export default FilteredSessions;
