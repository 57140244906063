import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { spotLabel } from 'views/Spots/Spots';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  count: {
    textAlign: 'right'
  }
}));

const TopSpotCard = props => {
  const { count, region, name } = props;

  const classes = useStyles();

  // There may be no spot
  const label = !!name ? spotLabel({region, name}) : null;

  return (
    <Grid
      container
      item
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Grid item xs={10}>
        <Typography
          variant="overline"
        >
          Top spot
        </Typography>
        <Typography variant="body1">{!!label ? label : <span>&mdash;</span>}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="h3" className={classes.count}>{count}</Typography>
      </Grid>
    </Grid>
  );
};

TopSpotCard.propTypes = {
  className: PropTypes.string,
  count: PropTypes.number,
  name: PropTypes.string,
  region: PropTypes.string,
};

export default TopSpotCard;
