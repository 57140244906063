import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import {
  Grid,
  TableCell,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import EditBoard from './EditBoard';
import {
  EntityTable,
} from '../../components';

import CheckTwoToneIcon from '@material-ui/icons/CheckTwoTone';
import sortBy from 'lodash/sortBy';

import firebase from 'firebase/app';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  boardName: {
    paddingRight: theme.spacing(1),
  },
  defaultIcon: {
    verticalAlign: 'inherit'
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const formatLength = (feet, inches) => {
  if (feet || inches) {
    return `${feet || 0}'${inches || 0}"`;
  }

  return '';
};

const boardLabel = (board, withType = false) => {
  const { lengthFeet, lengthInches, name, type } = board;
  const length = formatLength(lengthFeet, lengthInches);
  const parts = [`${length} ${name}`.trim()];

  if (withType) {
    parts.push(type);
  }

  return parts
    .filter(obj => obj)
    .join(' - ');
};

const byLength = board => {
  const {lengthFeet, lengthInches} = board;
  if (lengthFeet || lengthInches) {
    // This happens to work even when strings :|
    return lengthFeet * 12 + lengthInches * 1;
  }

  // Put those without length at the end
  return 100 * 12;
};

// Quick and dirty hack to put boards without type at the end
const byType = board => board.type || 'zzzz';
const byName = board => board.name;
const byArchived = board => !!board.isArchived;

const sortBoards = boards => sortBy(boards, [byType, byLength, byName]);

const sortBoardDocs = ({docs, archivedLast = true}) => {
  const sortFns = [byType, byLength, byName];
  if (archivedLast) {
    sortFns.unshift(byArchived);
  }

  return sortBy(docs, sortFns.map(fn => {
    return (doc) => fn(doc.data());
  }));
};

const BoardRow = props => {
  const classes = useStyles();
  const { entity: board, sessionCount } = props;
  const data = board.data();

  const length = formatLength(data.lengthFeet, data.lengthInches)

  return (
    <Fragment>
      <TableCell>{data.type}</TableCell>
      <TableCell>{length}</TableCell>
      <TableCell>
        <span className={classes.boardName}>{data.name}</span>
        {data.isDefault &&
          <Tooltip title="Default board for logging sessions">
            <CheckTwoToneIcon className={classes.defaultIcon} />
          </Tooltip>
        }
      </TableCell>
      <TableCell>{sessionCount}</TableCell>
    </Fragment>);
};

BoardRow.propTypes = {
  entity: PropTypes.object.isRequired,
  sessionCount: PropTypes.number.isRequired,
};

const BoardHeader = () => {
  return (<Fragment>
    <TableCell>Type</TableCell>
    <TableCell>Size</TableCell>
    <TableCell>Name</TableCell>
    <TableCell>Sessions</TableCell>
  </Fragment>);
}

const Boards = () => {
  const db = firebase.firestore();
  const userId = firebase.auth().currentUser.uid;

  const classes = useStyles();
  const [state, setState] = useState({
    boards: [],
    isLoading: true,
    editBoard: {
      isOpen: false,
      board: null
    }
  });

  const { enqueueSnackbar } = useSnackbar();

  const boardCollection = db.collection('users')
    .doc(userId)
    .collection('boards');

  const fetchBoards = () => {
    setState(state => ({
      ...state,
      isLoading: true
    }));

    const unsubscribe = boardCollection.onSnapshot(querySnapshot => {
      setState(state => ({
        ...state,
        boards: querySnapshot.docs,
        isLoading: false
      }));
    }, () => {
      enqueueSnackbar('There was an error loading your boards')
      setState(state => ({
        ...state,
        isLoading: false
      }));
    });

    return unsubscribe;
  };

  useEffect(fetchBoards, []);

  const openEditDialog = (board = null) => {
    setState(state => ({...state, editBoard: { isOpen: true, board }}));
  };

  const closeEditDialog = () => {
    setState(state => ({...state, editBoard: { isOpen: false, board: null }}));
  };

  const boardsSorted = sortBoardDocs({docs: state.boards});
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h1">
            Boards
          </Typography>
        </Grid>
        <Grid item xs={6} container justify="flex-end">
          <EditBoard
            handleClick={openEditDialog}
            handleClose={closeEditDialog}
            isOpen={state.editBoard.isOpen}
            board={state.editBoard.board}
          />
        </Grid>
      </Grid>
      <div className={classes.content}>
        <EntityTable
          entities={boardsSorted}
          dbCollection={boardCollection}
          headerComponent={BoardHeader}
          isLoading={state.isLoading}
          labelNullState="Add boards to your quiver to use them when logging sessions"
          labelPlural="boards"
          labelSingular="board"
          onEdit={openEditDialog}
          rowComponent={BoardRow}
          statsKey="boards"
          surfLogProperty="boardRef"
        />
      </div>
    </div>
  );
};

export default Boards;
export {
  sortBoards,
  sortBoardDocs,
  boardLabel,
};
