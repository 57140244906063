import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import clsx from 'clsx';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Alert,
  Autocomplete,
} from '@material-ui/lab';

import { useSnackbar } from 'notistack';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import map from 'lodash/map';
import mergeWith from 'lodash/mergeWith';
import maxBy from 'lodash/maxBy';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';

import {
  BoardsChart,
  SpotsChart,
  TopSpotCard,
  TopFriendCard,
  TopBoardCard,
  SessionsChart,
  SessionCountCard,
  FilteredSessions,
} from './components';

import {
  boardLabel,
  sortBoards,
} from 'views/Boards/Boards';

import {
  sortSpots,
} from 'views/Spots/Spots';

import firebase from 'firebase/app';

const DEFAULT_PAGE_SIZE = 10;

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    flexGrow: 1,
  },
  content: {
    marginTop: theme.spacing(2)
  },
  // Buttons don't have any support for an active/selected state
  active: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    }
  },
}));

const sum = (objValue, srcValue) => (objValue || 0) + (srcValue || 0);

const Profile = () => {
  const classes = useStyles();
  const { friendId } = useParams();
  const db = firebase.firestore();
  const currentUserId = firebase.auth().currentUser.uid;
  const currentUserName = firebase.auth().currentUser.displayName;
  const { enqueueSnackbar } = useSnackbar();

  const userId = friendId || currentUserId;
  const isForCurrentUser = !friendId || (friendId === currentUserId);

  const [state, setState] = useState({
    isLoading: true,
    hasNotFoundError: false,
    ownerName: null,
    startDate: null,
    endDate: null,
    dateFilter: null,
    boardFilter: null,
    spotFilter: null,
    resources: {},
    surfSummaries: [],
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const {
    isLoading,
    hasNotFoundError,
    resources,
    ownerName,
    startDate,
    endDate,
    dateFilter,
    boardFilter,
    spotFilter,
    surfSummaries,
    pageSize,
  } = state;

  const handleMonthFilter = (newDateFilter) => {
    // If the user clicks the already selected filter, do nothing
    if (dateFilter === newDateFilter) {
      return;
    }

    let startDate, endDate;
    const today = new Date();
    const thisMonth = String(today.getMonth() + 1).padStart(2, '0');
    const thisYear = today.getFullYear();
    if (newDateFilter === 'allTime') {
      startDate = null;
      endDate = null;
    } else if (newDateFilter === 'ytd') {
      startDate = `${thisYear}-01-01`;
      endDate = `${thisYear}-${thisMonth}-01`;
    } else if (newDateFilter === '1y') {
      startDate = `${thisYear - 1}-${thisMonth}-01`;
      endDate = `${thisYear}-${thisMonth}-01`;
    } else if (newDateFilter === '2y') {
      startDate = `${thisYear - 2}-${thisMonth}-01`;
      endDate = `${thisYear}-${thisMonth}-01`;
    } else if (newDateFilter === '3y') {
      startDate = `${thisYear - 3}-${thisMonth}-01`;
      endDate = `${thisYear}-${thisMonth}-01`;
    }

    setState(state => ({
      ...state,
      dateFilter: newDateFilter,
      startDate,
      endDate,
      pageSize: DEFAULT_PAGE_SIZE,
    }));
  };

  const handleLoadingError = (err) => {
    const notFound = (err.code === 'permission-denied');
    setState(state => ({
      ...state,
      hasNotFoundError: notFound,
      isLoading: false,
    }));

    if (!notFound) {
      // The not found case is handled through an alert on the page
      // rather than a snackbar
      enqueueSnackbar('There was an error loading this page.');
    }
  };

  const handlePageIncrease = () => {
    setState(state => ({
      ...state,
      pageSize: state.pageSize + DEFAULT_PAGE_SIZE,
    }));
  };

  const fetchData = () => {
    const resources = db.collection('resources')
      .doc(userId)
      .get()
      .then(doc => doc.exists ? doc.data() : {});

    let ownerName = Promise.resolve(currentUserName);
    if (!isForCurrentUser) {
      ownerName = db.collection('users')
        .doc(currentUserId)
        .collection('friends')
        .doc(friendId)
        .get()
        .then(doc => doc.exists ? doc.data().name : null);
    }

    const sessions = db.collection('users')
      .doc(userId)
      .collection('surfSummaries')
      .get()
      .then(querySnapshot => querySnapshot.docs);

    Promise.all([resources, ownerName, sessions])
      .then(([resources, ownerName, sessions]) => {

        const surfSummaries = [];
        sessions.forEach(d => {
          const shard = d.data().summaries;
          forEach(shard, (surfSummary, surfId) => {
            let waveQuality, hollowness, crowdedness, funFactor;
            [
              waveQuality,
              hollowness,
              crowdedness,
              funFactor
            ] = surfSummary.qhcf.split('').map(v => parseInt(v))

            const {
              bId: boardId,
              sId: spotId,
            } = surfSummary;

            surfSummaries.push({
              id: surfId,
              sessionDate: DateTime.fromJSDate(surfSummary.sAt.toDate(), { zone: surfSummary.tz || 'America/Los_Angeles' }),
              duration: surfSummary.d,
              boardId,
              boardType: get(resources, ['boards', boardId, 'type']),
              spotId,
              region: get(resources, ['spots', spotId, 'region']),
              friendIds: surfSummary.fIds || [],
              waveQuality,
              hollowness,
              crowdedness,
              funFactor,
            });
          });
        });

        setState(state => ({
          ...state,
          resources,
          ownerName,
          surfSummaries: orderBy(surfSummaries, ['sessionDate'], ['desc']),
          isLoading: false,
        }));

        handleMonthFilter('1y');
      })
      .catch(handleLoadingError);
  };

  useEffect(fetchData, []);

  if (isLoading) {
    return (
      <div className={classes.root}>
        <Grid container justify="center">
          <Box p={5}>
            <CircularProgress />
          </Box>
        </Grid>
      </div>
    );
  }

  let filteredCount = 0;
  const filteredSummaries = [];
  const months = {};
  const boards = {};
  const boardTypes = {};
  const spots = {};
  const spotRegions = {};
  const friends = {};

  forEach(surfSummaries, session => {
    const { boardId, boardType, spotId, region, friendIds, sessionDate } = session;

    const monthKey = sessionDate.toFormat('yyyy-MM');

    const month = `${monthKey}-01`
    const isInRange = (!startDate || month >= startDate) && (!endDate || month <= endDate)

    if (!isInRange) {
      // limit to months within the selected date range
      return;
    }

    const matchesBoardFilter = !boardFilter || boardId === boardFilter.id;
    if (!matchesBoardFilter) {
      return;
    }

    const matchesSpotFilter = !spotFilter || spotId === spotFilter.id;
    if (!matchesSpotFilter) {
      return;
    }

    filteredCount++;
    filteredSummaries.push(session);

    mergeWith(months, { [month]: 1 }, sum);

    if (boardId) {
      mergeWith(boards, { [boardId]: 1 }, sum);
      if (boardType) {
        mergeWith(boardTypes, { [boardType]: 1 }, sum);
      }
    }

    if (spotId) {
      mergeWith(spots, { [spotId]: 1 }, sum);
      if (region) {
        mergeWith(spotRegions, { [region]: 1 }, sum);
      }
    }

    forEach(friendIds, friendId => {
      mergeWith(friends, { [friendId]: 1 }, sum);
    });
  });

  const monthsSorted = sortBy(map(months, (count, monthKey) => ({ x: monthKey, y: count })), 'x');
  const topBoardId = maxBy(Object.keys(boards), b => boards[b] );
  const topSpotId = maxBy(Object.keys(spots), b => spots[b]);
  const topFriendId = maxBy(Object.keys(friends), b => friends[b]);

  const topSpot = get(resources, ['spots', topSpotId], {});
  const topBoard = get(resources, ['boards', topBoardId], {});
  const topFriend = get(resources, ['friends', topFriendId], {});
  let acceptedAt = null;

  if (topFriend.acceptedAt) {
    acceptedAt = DateTime
      .fromJSDate(topFriend.acceptedAt.toDate())
      .toLocaleString({ month: 'short', year: 'numeric' });
  }

  if (hasNotFoundError) {
    return <div className={classes.root}>
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
        >
          <Alert severity="error">Friend not found</Alert>
        </Grid>
      </Grid>
    </div>
  }

  const hasEverLogged = !!surfSummaries.length;
  const pagedSurfs = filteredSummaries.slice(0, pageSize);

  const boardFilterOptions = sortBoards(map(resources.boards, (board, id) => ({ id, ...board })));
  const spotFilterOptions = sortSpots(map(resources.spots, (spot, id) => ({ id, ...spot })));

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h1">
            {ownerName}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.content}>
      <Grid
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Card>
          <CardContent>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <ButtonGroup fullWidth={true}>
              <Button
                className={clsx(dateFilter === 'allTime' && classes.active)}
                onClick={() => handleMonthFilter('allTime')}
              >
                ALL
              </Button>
              <Button
                className={clsx(dateFilter === 'ytd' && classes.active)}
                onClick={() => handleMonthFilter('ytd')}
              >
                YTD
              </Button>
              <Button
                className={clsx(dateFilter === '1y' && classes.active)}
                onClick={() => handleMonthFilter('1y')}
              >
                1Y
              </Button>
              <Button
                className={clsx(dateFilter === '2y' && classes.active)}
                onClick={() => handleMonthFilter('2y')}
              >
                2Y
              </Button>
              <Button
                className={clsx(dateFilter === '3y' && classes.active)}
                onClick={() => handleMonthFilter('3y')}
              >
                3Y
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              size="small"
              value={boardFilter}
              options={boardFilterOptions}
              groupBy={(option) => option.type || 'No type'}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(b, _) => boardLabel(b)}
              blurOnSelect
              onChange={(_event, option) => {
                setState(state => ({
                  ...state,
                  boardFilter: option,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Board"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              size="small"
              value={spotFilter}
              options={spotFilterOptions}
              groupBy={(option) => option.region || 'No region'}
              disabled={false}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(spot) => spot.name}
              blurOnSelect
              onChange={(_event, option) => {
                setState(state => ({
                  ...state,
                  spotFilter: option,
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Spot"
                />
              )}
            />
          </Grid>
          </Grid>
          </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
        >
          <Card style={{height: '100%'}}>
            <CardContent style={{ height: '100%' }}>
              <Grid
                container
                direction="column"
                justify="space-around"
                style={{height: '100%'}}
              >
                <SessionCountCard count={filteredCount}/>
                <Divider />
                <TopSpotCard
                  count={spots[topSpotId]}
                  name={topSpot.name}
                  region={topSpot.region}
                />
                <Divider />
                <TopBoardCard
                  count={boards[topBoardId]}
                  name={topBoard.name}
                  lengthFeet={topBoard.lengthFeet}
                  lengthInches={topBoard.lengthInches}
                  type={topBoard.type}
                />
                <Divider />
                <TopFriendCard
                  count={friends[topFriendId]}
                  name={topFriend.name}
                  friendsSince={acceptedAt}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          md={8}
          xs={12}
        >
          <SessionsChart data={monthsSorted} hasEverLogged={hasEverLogged} />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <BoardsChart
            boards={boards}
            types={boardTypes}
            resources={resources.boards}
            hasEverLogged={hasEverLogged}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <SpotsChart
            spots={spots}
            regions={spotRegions}
            resources={resources.spots}
            hasEverLogged={hasEverLogged}
          />
        </Grid>
        <Grid
          container
          item
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h4">
              Session Logs
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              Showing {pagedSurfs.length} of {filteredSummaries.length}
            </Typography>
          </Grid>
        </Grid>
        <FilteredSessions hasEverLogged={hasEverLogged} surfSummaries={pagedSurfs} userId={userId} />
        <Grid item xs={12}>
          <Button
            variant="contained"
            disabled={pagedSurfs.length === filteredSummaries.length}
            onClick={handlePageIncrease}
            pt={2}
          >
            Load More
          </Button>
        </Grid>
      </Grid>
      </div>
    </div>
  );
};

export default Profile;
