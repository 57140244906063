import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  heroContent: {
    padding: theme.spacing(4, 1),
  },
  description: {
    marginTop: theme.spacing(4),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  heroButton: {
    minWidth: 200
  }
}));

export default function Album() {
  const classes = useStyles();

  return (
    <div>
      {/* Hero unit */}
      <div className={classes.heroContent}>
        <Container maxWidth="sm">
          <Typography variant="h2" align="center" gutterBottom>
            A Simple Surf Journal
          </Typography>
          <Typography className={classes.description} variant="body1" align="center" color="textSecondary" paragraph>
            Thrashtown is an easy way to track your surf sessions over time.
            Use it on your own or connect with your regular surfing buddies to share the stoke with each other!
          </Typography>
          <div className={classes.heroButtons}>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Button
                  className={classes.heroButton}
                  variant="contained"
                  size="large"
                  color="primary"
                  to="/sign-up"
                  component={RouterLink}>
                    Sign Up
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.heroButton}
                  variant="outlined"
                  size="large"
                  color="primary"
                  to="/log-in"
                  component={RouterLink}>
                    Log In
                </Button>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    </div>
  );
}
