import React, { useEffect, useState } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import get from 'lodash/get';
import firebase from 'firebase/app';
import Footer from '../../layouts/Footer'

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
      minimum: 3,
      tokenizer: (value) => {
        // This removes multiple-spaces between words and beg/end of words
        return value.split(' ').filter(v => v).join(' ');
      },
    },
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 254
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignUp = props => {
  const { history } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    isSubmitting: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    // TODO(ramin): revisit this or at least share this code amoung the forms
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignUp = event => {
    event.preventDefault();
    closeSnackbar();
    setFormState(formState => ({
      ...formState,
      isSubmitting: true
    }));

    const {email, password, name} = formState.values;
    const userName = name.trim();
    firebase.auth().createUserWithEmailAndPassword(email, password).then(result => {
      return result.user.updateProfile({
        displayName: userName
      })
      .catch(() => {
        return {
          message: 'Something went wrong saving the name'
        };
      })
      .finally(() => {
        history.push('/')
      });
    })
    .catch((reason) => {
      enqueueSnackbar(get(reason, 'message', 'Something went wrong'));
      setFormState(formState => ({
          ...formState,
          isSubmitting: false
        }));
      return;
    });
  };

  // TODO(ramin): DRY this up!
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.contentBody}>
          <form
            className={classes.form}
            onSubmit={handleSignUp}
          >
            <Typography
              className={classes.title}
              variant="h2"
            >
              Sign Up
            </Typography>
            <TextField
              className={classes.textField}
              error={hasError('name')}
              fullWidth
              helperText={
                hasError('name') ? formState.errors.name[0] : null
              }
              label="Name *"
              name="name"
              onChange={handleChange}
              type="text"
              value={formState.values.name || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={hasError('email')}
              fullWidth
              helperText={
                hasError('email') ? formState.errors.email[0] : null
              }
              label="Email *"
              name="email"
              onChange={handleChange}
              type="text"
              value={formState.values.email || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={hasError('password')}
              fullWidth
              helperText={
                hasError('password') ? formState.errors.password[0] : null
              }
              label="Password *"
              name="password"
              onChange={handleChange}
              type="password"
              value={formState.values.password || ''}
              variant="outlined"
            />
            <Button
              className={classes.signUpButton}
              color="primary"
              disabled={!formState.isValid || formState.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Sign up
            </Button>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              Have an account?{' '}
              <Link
                component={RouterLink}
                to="/log-in"
                variant="h6"
              >
                Log in
              </Link>
            </Typography>
            <Footer />
          </form>
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
export { schema as UserValidationSchema };
