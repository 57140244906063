import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import FriendsTable from './FriendsTable';
import AddFriend from './AddFriend';

import firebase from 'firebase/app';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Friends = () => {
  const db = firebase.firestore();
  const userId = firebase.auth().currentUser.uid;

  const classes = useStyles();
  const [state, setState] = useState({
    friends: [],
    stats: {},
    isLoadingFriends: true,
    isLoadingStats: true,
    addFriend: {
      isOpen: false
    }
  });

  const { enqueueSnackbar } = useSnackbar();

  const fetchFriends = () => {
    setState(state => ({
      ...state,
      isLoadingFriends: true
    }));

    const unsubscribe = db.collection('users')
      .doc(userId)
      .collection('friends')
      .onSnapshot(querySnapshot => {
        setState(state => ({
          ...state,
          friends: querySnapshot.docs,
          isLoadingFriends: false
        }));
      }, () => {
        enqueueSnackbar('There was an error loading your friends')
        setState(state => ({
          ...state,
          isLoadingFriends: false
        }));
      });

    return unsubscribe;
  };

  const fetchStats = () => {
    db.collection('stats')
      .doc(userId)
      .get()
      .then(doc => {
        let stats = {};
        if (doc.exists) {
          stats = doc.data();
        }

        setState(state => ({
          ...state,
          stats,
          isLoadingStats: false,
        }));
      })
      .catch(err => {
        console.log(err);
        enqueueSnackbar('There was an error loading your data');
      });
  };

  useEffect(fetchFriends, []);
  useEffect(fetchStats, []);

  const openEditDialog = () => {
    setState(state => ({...state, addFriend: { isOpen: true }}));
  };

  const closeEditDialog = () => {
    setState(state => ({...state, addFriend: { isOpen: false }}));
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h1">
            Friends
          </Typography>
        </Grid>
        <Grid item xs={6} container justify="flex-end">
          <AddFriend
            handleClick={openEditDialog}
            handleClose={closeEditDialog}
            isOpen={state.addFriend.isOpen}
          />
        </Grid>
      </Grid>
      <div className={classes.content}>
        <FriendsTable
          friends={state.friends}
          stats={state.stats}
          isLoading={state.isLoadingFriends || state.isLoadingStats}
        />
      </div>
    </div>
  );
};

export default Friends;
