import React, { Fragment, useEffect, useState } from 'react';
import sortBy from 'lodash/sortBy';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  TableCell,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CheckTwoToneIcon from '@material-ui/icons/CheckTwoTone';
import EditSpot from './EditSpot';
import {
  EntityTable,
} from '../../components';

import firebase from 'firebase/app';

const spotLabel = ({name, region}) => {
  return [region, name].filter(i => i).join(' - ');
}

// Quick and dirty hack to sort spots without region at end
const byRegion = spot => spot.region || 'zzzz';
const byName = spot => spot.name;
const byArchived = spot => !!spot.isArchived;

const sortSpots = spots => sortBy(spots, [byRegion, byName]);

const sortSpotDocs = ({ docs, archivedLast = true }) => {
  const sortFns = [byRegion, byName];
  if (archivedLast) {
    sortFns.unshift(byArchived);
  }

  return sortBy(docs, sortFns.map(fn => {
    return (doc) => fn(doc.data());
  }));
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  defaultIcon: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'inherit'
  },
}));

const SpotRow = props => {
  const classes = useStyles();
  const { entity: spot, sessionCount } = props;
  const data = spot.data();

  return (
    <Fragment>
      <TableCell>{data.region}</TableCell>
      <TableCell>
        {data.name}
        {data.isDefault &&
          <Tooltip title="Default spot for logging sessions">
            <CheckTwoToneIcon className={classes.defaultIcon} />
          </Tooltip>
        }
      </TableCell>
      <TableCell>{sessionCount}</TableCell>
    </Fragment>);
};

SpotRow.propTypes = {
  entity: PropTypes.object.isRequired,
  sessionCount: PropTypes.number.isRequired,
};

const SpotHeader = () => {
  return (<Fragment>
    <TableCell>Region</TableCell>
    <TableCell>Name</TableCell>
    <TableCell>Sessions</TableCell>
  </Fragment>);
};

const Spots = () => {
  const db = firebase.firestore();
  const userId = firebase.auth().currentUser.uid;

  const classes = useStyles();
  const [state, setState] = useState({
    spots: [],
    isLoading: true,
    editSpot: {
      isOpen: false,
      spot: null
    }
  });

  const { enqueueSnackbar } = useSnackbar();

  const spotCollection = db.collection('users')
    .doc(userId)
    .collection('spots');

  const fetchSpots = () => {
    setState(state => ({
      ...state,
      isLoading: true
    }));

    const unsubscribe = spotCollection.onSnapshot(querySnapshot => {
      setState(state => ({
        ...state,
        spots: querySnapshot.docs,
        isLoading: false
      }));
    }, () => {
      enqueueSnackbar('There was an error loading your spots')
      setState(state => ({
        ...state,
        isLoading: false
      }));
    });

    return unsubscribe;
  };

  useEffect(fetchSpots, []);

  const openEditDialog = (spot = null) => {
    setState(state => ({...state, editSpot: { isOpen: true, spot }}));
  };

  const closeEditDialog = () => {
    setState(state => ({...state, editSpot: { isOpen: false, spot: null }}));
  };

  const spotsSorted = sortSpotDocs({docs: state.spots});

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h1">
            Spots
          </Typography>
        </Grid>
        <Grid item xs={6} container justify="flex-end">
          <EditSpot
            handleClick={openEditDialog}
            handleClose={closeEditDialog}
            isOpen={state.editSpot.isOpen}
            spot={state.editSpot.spot}
          />
        </Grid>
      </Grid>
      <div className={classes.content}>
        <EntityTable
          dbCollection={spotCollection}
          entities={spotsSorted}
          headerComponent={SpotHeader}
          isLoading={state.isLoading}
          labelNullState="Add spots to use them when logging sessions"
          labelPlural="spots"
          labelSingular="spot"
          onEdit={openEditDialog}
          rowComponent={SpotRow}
          statsKey="spots"
          surfLogProperty="spotRef"
        />
      </div>
    </div>
  );
};

export default Spots;
export {
  sortSpots,
  sortSpotDocs,
  spotLabel,
};
