import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Divider,
  Drawer,
} from '@material-ui/core';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import LocationOnTwoTone from '@material-ui/icons/LocationOnTwoTone';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import { Context } from '../../../../Store';

import { withRouter } from 'react-router-dom';
import firebase from 'firebase/app';

import { Profile, SidebarNav } from './components';
import { BoardIcon } from 'icons';
import { TOPBAR_HEIGHT_LG } from '../../../../layouts/Main/Main'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: TOPBAR_HEIGHT_LG,
      height: `calc(100% - ${TOPBAR_HEIGHT_LG}px)`
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = props => {
  const { history, open, variant, onClose, className } = props;
  const [, dispatch] = useContext(Context);

  const classes = useStyles();

  const pages = [
    {
      title: 'Feed',
      href: '/',
      icon: <ListAltTwoToneIcon />,
      exact: true,
    },
    {
      title: 'Log Session',
      href: '/surf',
      icon: <AddCircleTwoToneIcon />,
      // Because it might be /surf for new or /surf/:id for edits
      exact: false,
    },
    {
      title: 'Friends',
      href: '/friends',
      icon: <PeopleAltTwoToneIcon />,
      exact: true,
    },
    {
      title: 'Boards',
      href: '/boards',
      icon: <BoardIcon />,
      exact: true,
    },
    {
      title: 'Spots',
      href: '/spots',
      icon: <LocationOnTwoTone />,
      exact: true,
    },
    {
      title: 'Account',
      href: '/account',
      icon: <SettingsTwoToneIcon />,
      exact: true,
    },
    {
      title: 'Sign Out',
      onClick: () => firebase.auth().signOut()
        .then(() => history.push('/log-in'))
        .then(() => dispatch({type: 'LOGOUT'})),
      icon: <ExitToAppIcon />
    },
  ];

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        className={clsx(classes.root, className)}
      >
        <Profile onClick={onClose}/>
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
          onClick={onClose}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  // React router
  history: PropTypes.object
};

export default withRouter(Sidebar);
