import get from 'lodash/get';
import firebase from 'firebase/app';

const signIn = (email, password, location) => {
  return firebase.auth().signInWithEmailAndPassword(email, password).then(() => {
    const path = get(location, 'state.from.pathname', '/');
    // Note: this is somewhat of a hack - it uses a page refresh.  It exists due to the
    // logic for showing whether  or not there is a logged in user in App.js.
    // To avoid this I would need to manage slightly differently the logic in
    // App.js `onAuthStateChanged`.
    // I would need to at a minimum set `isCheckingAuth` to true here (i.e.
    // with a cb or redux).  This hack forces the app to reload,
    // which ends up setting `isCheckingAuth` to true.
    // I couldn't find great examples of solutions for this for react <-> firebase apps.
    window.location = path;
  })
};

export { signIn };
